import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

import { APP_NAME, APP_VERSION, GetActiveAPIUrl } from "../helpers/config";
// eslint-disable-next-line import/no-cycle
import { GetLocalAuthToken } from "./MoblyzeAuth";

const httpLink = createUploadLink({
  uri: GetActiveAPIUrl(),
});

const authLink = setContext(async (_, { headers }) => {
  const token = await GetLocalAuthToken();

  return {
    headers: {
      ...headers,
      appID: "moblyze-web",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const MoblyzeApolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  name: APP_NAME,
  version: APP_VERSION,
  cache: new InMemoryCache(),
  fetchPolicy: "network-only",
});

export default MoblyzeApolloClient;
