import React, { useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { Flex, Text, Stack, Input } from "@chakra-ui/react";

import ShortlistEmptyState from "./ShortlistEmptyState";
import CandidateListItem from "./CandidateListItem";
import CandidateDetails from "./CandidateDetails";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as Information } from "../../assets/icons/information.svg";

import { AGENCY_PROSPECTIVE_CANDIDATES_FOR_JOB_QUERY } from "../../graphql/jobs";
import WithQuery from "../WithQuery";
import { LoadingIndicator } from "../LoadingIndicator";

function AgencyAddMoreCandidatesBody({
  moblyzeJob,
  isArchived,
  tabName,
  agencyProspectiveCandidatesForJob,
  refetch,
}) {
  const emptyMessage = `There are no more prospective candidates for this job.`;

  const [searchText, setSearchText] = useState("");

  const [candidate, setCandidate] = useState(
    agencyProspectiveCandidatesForJob?.length > 0
      ? agencyProspectiveCandidatesForJob[0]
      : null
  );

  React.useEffect(() => {
    setCandidate(
      agencyProspectiveCandidatesForJob?.length > 0
        ? agencyProspectiveCandidatesForJob[0]
        : null
    );
  }, [agencyProspectiveCandidatesForJob]);

  if (agencyProspectiveCandidatesForJob?.length === 0) {
    return (
      <Stack spacing="3">
        <Flex gap="50px" alignItems={"center"} justifyContent={"center"}>
          <ShortlistEmptyState message={emptyMessage} />
        </Flex>
      </Stack>
    );
  }

  const candidateReportList = agencyProspectiveCandidatesForJob?.map(
    (candidate) => {
      // filter by search text
      const fullName = `${candidate?.firstName} ${candidate?.lastName}`;
      if (
        searchText &&
        searchText.length > 0 &&
        !fullName?.toLowerCase().includes(searchText)
      ) {
        return null;
      }

      return {
        candidateUser: candidate,
      };
    }
  );

  return (
    <Stack spacing="3">
      {/* render search input */}
      <Flex alignItems={"center"} justifyContent={"flex-start"} w="100%" mb="5">
        <Flex alignItems={"center"} gap="12px">
          <Input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchText(e?.target?.value?.toLowerCase())}
          />
        </Flex>
      </Flex>

      {candidateReportList && candidateReportList?.length > 0 ? (
        <Flex gap="50px" alignItems={"flex-start"} justifyContent={"center"}>
          <CandidateListItem
            setSelect={setCandidate}
            select={candidate}
            candidateReportList={candidateReportList}
          />
          {candidate && (
            <CandidateDetails
              refetch={refetch}
              moblyzeJob={moblyzeJob}
              candidateReport={{ candidateUser: candidate }}
              showAgencyAddToShortlistAction={!isArchived}
            />
          )}
        </Flex>
      ) : (
        <Text>No candidate found.</Text>
      )}
    </Stack>
  );
}

function AgencyAddMoreCandidates({
  moblyzeJob,
  onBackButtonClicked,
  data,
  error,
  loading,
  refetch,
}) {
  if (!moblyzeJob) {
    return null;
  }

  const isArchived = moblyzeJob.status === "Closed";

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mt="5" mb="10">
        <Text
          onClick={onBackButtonClicked}
          fontSize="md"
          lineHeight="6"
          fontWeight="normal"
          cursor="pointer"
        >
          <Icon fontSize="10px" as={LeftArrow} /> Back to Shortlist
        </Text>

        <Flex alignItems="center" gap="12px">
          <Icon fontSize="24px" as={Information} />
          <Text fontSize="md" fontWeight="normal" lineHeight="6">
            You shortlist will be auto-saved and updated as you add more
            candidates
          </Text>
        </Flex>
      </Flex>

      {loading ? (
        <LoadingIndicator />
      ) : (
        <AgencyAddMoreCandidatesBody
          moblyzeJob={moblyzeJob}
          agencyProspectiveCandidatesForJob={
            data?.agencyProspectiveCandidatesForJob
          }
          isArchived={isArchived}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default function AgencyAddMoreCandidatesComponent({
  moblyzeJob,
  onBackButtonClicked,
}) {
  return WithQuery(
    AgencyAddMoreCandidates,
    AGENCY_PROSPECTIVE_CANDIDATES_FOR_JOB_QUERY,
    { moblyzeJobId: moblyzeJob.id },
    {
      moblyzeJob,
      onBackButtonClicked,
    }
  )();
}
