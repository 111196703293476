import React, { useState } from "react";
import { Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";

import {
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Flex,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";

import { Icon } from "@chakra-ui/icons";

import colors from "../helpers/colors";

import { ReactComponent as MenuIcon } from "../assets/icons/menu.svg";

import MoblyzeApolloClient from "../services/MoblyzeApolloClient";

import { ACTIVE_JOBS_PATH, ARCHIVED_JOBS_PATH, EDIT_JOB_PATH } from "../routes";

import FormModal from "./FormModal";

import {
  MoblyzeClosedReasons,
  AGENCY_CLOSE_JOB_MUTATION,
  AGENCY_REACTIVATE_JOB_MUTATION,
} from "../graphql/jobs";

function ArchiveJobModal({ isOpen, onOpen, onClose, moblyzeJob }) {
  const toast = useToast();
  const navigate = useNavigate();

  const [closedReason, setClosedReason] = useState("");
  const [closedNote, setClosedNote] = useState("");

  const onSubmit = () => {
    if (!closedReason) {
      alert("Please select a reason for archiving this job.");

      return;
    }

    MoblyzeApolloClient.mutate({
      mutation: AGENCY_CLOSE_JOB_MUTATION,
      variables: {
        moblyzeJobId: moblyzeJob.id,
        reason: closedReason,
        closedNote,
      },
    })
      .then((result) => {
        toast({
          title: "Job archived",
          status: "success",
        });

        navigate(ARCHIVED_JOBS_PATH);
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };

  return (
    <>
      <FormModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        submitTitle="Archive Job"
        title="Are you sure you want to archive this job?"
      >
        <FormControl>
          <FormLabel fontSize="sm">Reason for Archiving</FormLabel>
          <Select
            placeholder="Select"
            color={colors.charcoal}
            mb="4"
            onChange={(e) => setClosedReason(e.target.value)}
            value={closedReason}
          >
            {MoblyzeClosedReasons.map((reason) => (
              <option key={reason} value={reason}>
                {/* change camel case to title case */}
                {reason.replace(/([A-Z])/g, " $1").trim()}
              </option>
            ))}
          </Select>

          <FormLabel fontSize="sm">Add Note</FormLabel>
          <Textarea
            mb="8"
            color={colors.charcoal}
            onChange={(e) => setClosedNote(e.target.value)}
            value={closedNote}
          />
        </FormControl>
      </FormModal>
    </>
  );
}

export function AgencyEditArchiveJobMenu({ moblyzeJob }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  return (
    <>
      <ArchiveJobModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        moblyzeJob={moblyzeJob}
      />
      <Menu>
        <MenuButton as={Button} variant="ghost" colorScheme="white">
          <Icon fontSize={30} as={MenuIcon} cursor="pointer" />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate(EDIT_JOB_PATH.replace(":id", moblyzeJob.id));
            }}
          >
            Edit Job
          </MenuItem>
          <MenuItem onClick={onOpen}>Archive Job</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

function AgencyReactivateJobModal({ isOpen, onOpen, onClose, moblyzeJob }) {
  const toast = useToast();
  const navigate = useNavigate();

  const [reactivationNote, setReactivationNote] = useState("");

  const onSubmit = () => {
    if (!reactivationNote) {
      alert("Please add a note to reactivate this job.");

      return;
    }

    MoblyzeApolloClient.mutate({
      mutation: AGENCY_REACTIVATE_JOB_MUTATION,
      variables: {
        moblyzeJobId: moblyzeJob.id,
        reactivationNote,
      },
    })
      .then((result) => {
        toast({
          title: "Job reactivated!",
          status: "success",
        });

        navigate(ACTIVE_JOBS_PATH);
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };

  return (
    <>
      <FormModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        submitTitle="Reactivate Job"
        title={`Are you sure you want to reactivate this job?\n
Reactivating this job will notify the client and appear on the app for candidates to apply.`}
      >
        <FormControl>
          <FormLabel fontSize="sm">Add Note</FormLabel>
          <Textarea
            mb="8"
            color={colors.charcoal}
            onChange={(e) => setReactivationNote(e.target.value)}
            value={reactivationNote}
          />
        </FormControl>
      </FormModal>
    </>
  );
}

export function AgencyReactivateJobAction({ moblyzeJob }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AgencyReactivateJobModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        moblyzeJob={moblyzeJob}
      />
      <Button onClick={onOpen} variant="link" colorScheme="white">
        Reactivate Job
      </Button>
    </>
  );
}
