import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { SUPPORT_EMAIL } from "../helpers/config";
import { ClaimInvite } from "../services/MoblyzeAuth";
import { CreateAccountTermsText } from "./TermsLinks";

export default function ClaimInviteForm(props) {
  let navigate = useNavigate();
  const toast = useToast();

  const { inviteFirstName, inviteToken, inviteEmail, ...otherProps } = props;

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onSubmitButtonPress = () => {
    if (password === "" || confirmPassword === "") {
      toast({
        title: "Please enter a password.",
        status: "error",
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        title: "Passwords do not match.",
        status: "error",
      });
      return;
    }

    setLoading(true);

    ClaimInvite({ inviteToken, password })
      .then((data) => {
        toast({
          title: "Welcome!",
          status: "success",
        });
        // navigate
        navigate(`/`);
      })
      .catch((e) => {
        console.log("ClaimInvite Error:", e);
        toast({
          title: "Error claiming invite.",
          description: e?.message,
          status: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack spacing="8" {...otherProps}>
      <Stack spacing="6">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={{ base: "sm", md: "md" }} mb="3">
            Hey {inviteFirstName},
          </Heading>
          <Text fontSize={{ base: "md", md: "lg" }} fontWeight={600} mb="3">
            Claim the account associated with email:
            <br />
            {inviteEmail}
          </Text>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="password" fontSize="xs" color={"colors.white"}>
              Password
            </FormLabel>
            <Input
              id="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              type="password"
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="confirmPassword"
              fontSize="xs"
              color={"colors.white"}
            >
              Confirm Password
            </FormLabel>
            <Input
              id="confirmPassword"
              placeholder="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              value={confirmPassword}
              type="password"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmitButtonPress();
                }
              }}
            />
          </FormControl>
        </Stack>

        <Stack spacing="6" textAlign={"center"} alignItems={"center"}>
          <Button
            onClick={onSubmitButtonPress}
            isLoading={loading}
            colorScheme={"brand"}
            w={"full"}
          >
            Create Account
          </Button>

          <CreateAccountTermsText />

          <Link
            fontSize="sm"
            mt={6}
            color="colors.white"
            textDecoration={"underline"}
            textDecorationColor={"colors.white"}
            href="/login"
          >
            Already have an account? Login
          </Link>

          <Text fontSize="sm">
            Not your preferred email?
            <br />
            <Link
              href={`mailto:${SUPPORT_EMAIL}`}
              color="colors.white"
              textDecoration={"underline"}
              textDecorationColor={"colors.white"}
            >
              Contact Moblyze
            </Link>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
