import React from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Text,
  NumberInput,
  NumberInputField,
  Textarea,
} from "@chakra-ui/react";

import colors from "../helpers/colors";
import { HookFilePicker } from "./Filepicker";
import { MultiSelectHook, SelectHook } from "./MultiSelect";
import { Controller } from "react-hook-form";

export const BasicInput = ({
  name,
  label,
  placeholder,
  typeOverride,
  required = false,
  register,
  errors,
  control,
  disabled = false,
}) => {
  const type = typeOverride ? typeOverride : "text";

  let inputInner = null;

  if (type === "number") {
    inputInner = (
      <Controller
        name={name}
        control={control}
        rules={{
          required,
        }}
        render={({ field: { ref, ...restField } }) => (
          <NumberInput
            placeholder={placeholder}
            {...restField}
            variant="outlineDark"
            min={0}
          >
            <NumberInputField ref={ref} name={restField.name} />
          </NumberInput>
        )}
      />
    );
  } else if (type === "textarea") {
    inputInner = (
      <Textarea
        {...register(name, { required })}
        id={name}
        placeholder={placeholder}
        variant="outlineDark"
      />
    );
  } else {
    inputInner = (
      <Input
        {...register(name, { required })}
        id={name}
        placeholder={placeholder}
        variant="outlineDark"
        type={type}
        disabled={disabled}
      />
    );
  }

  return (
    <FormControl isInvalid={errors[name]} isRequired={required} mb="5" ml="3">
      <FormLabel fontSize="md" color="white" htmlFor={name}>
        {label}
      </FormLabel>
      {inputInner}
      <FormErrorMessage>
        {errors[name] &&
          (errors[name].message === "" ? "Required" : errors[name].message)}
      </FormErrorMessage>
    </FormControl>
  );
};

export const SelectInput = ({
  name,
  label,
  placeholder,
  required = false,
  options = [],
  register,
  control,
  errors,
  disabled = false,
}) => {
  return (
    <FormControl isInvalid={errors[name]} isRequired={required} mb="5" ml="3">
      <FormLabel fontSize="md" color="white" htmlFor={name}>
        {label}
      </FormLabel>
      <SelectHook
        control={control}
        inputName={name}
        id={name}
        placeholder={placeholder}
        borderColor={colors.greyMedium}
        disabled={disabled}
        variant={"outlineDark"}
        options={options}
        required={required}
      />
      <FormErrorMessage>
        {errors[name] &&
          (errors[name].message === "" ? "Required" : errors[name].message)}
      </FormErrorMessage>
    </FormControl>
  );
};

export const MultiSelectInput = ({
  name,
  label,
  placeholder,
  required = false,
  options = [],
  errors,
  control,
}) => {
  return (
    <FormControl isInvalid={errors[name]} isRequired={required} mb="5" ml="3">
      <FormLabel fontSize="md" color="white" htmlFor={name}>
        {label}
      </FormLabel>
      <MultiSelectHook
        control={control}
        inputName={name}
        options={options}
        required={required}
      />
      <FormErrorMessage>
        {errors[name] &&
          (errors[name].message === "" ? "Required" : errors[name].message)}
      </FormErrorMessage>
    </FormControl>
  );
};

export const ImageUpload = ({
  name,
  label,
  placeholder,
  required = false,
  errors,
  control,
}) => {
  return (
    <HookFilePicker
      control={control}
      inputName={name}
      placeholder={placeholder}
      required={required}
      acceptedFileTypes="image/*"
      variant="outlineDark"
      errors={errors}
      label={label}
    />
  );
};

export const SectionTitle = ({ title }) => {
  return (
    <Text fontSize="2xl" fontWeight="500" color="white" mb="5" mt="10">
      {title}
    </Text>
  );
};

export const Spacer = () => {
  return <div style={{ height: "20px" }} />;
};
