import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  useToast,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";

import { RESET_PASSWORD_MUTATION } from "../graphql/auth";
import MoblyzeApolloClient from "../services/MoblyzeApolloClient";
import { LOGIN_PATH } from "../routes";

export const ResetPasswordForm = (props) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onSubmitButtonPress = () => {
    if (!email || email === "") {
      return;
    }

    setLoading(true);

    MoblyzeApolloClient.mutate({
      mutation: RESET_PASSWORD_MUTATION,
      variables: {
        email,
      },
    })
      .then((res) => {
        console.log(res);

        toast({
          title:
            "If you have an account with us, you will receive an email with instructions on how to reset your password.",
          status: "success",
        });

        navigate(LOGIN_PATH);
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert("Error", e?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack spacing="8" {...props}>
      <Stack spacing="6">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={{ base: "sm", md: "md" }} mb="3">
            Reset Password
          </Heading>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="email" fontSize="xs" color={"colors.white"}>
              Email
            </FormLabel>
            <Input
              id="email"
              placeholder="Email"
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmitButtonPress();
                }
              }}
            />
          </FormControl>
        </Stack>

        <Stack spacing="6" textAlign={"center"} alignItems={"center"}>
          <Button
            onClick={onSubmitButtonPress}
            isLoading={loading}
            colorScheme={"brand"}
            w={"full"}
          >
            Request Password Reset
          </Button>

          <Link
            fontSize="sm"
            mt={6}
            color="colors.white"
            textDecoration={"underline"}
            textDecorationColor={"colors.white"}
            href="/login"
          >
            Back to Sign In
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
