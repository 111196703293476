import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Icon,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import { ReactComponent as Like } from "../../assets/icons/like.svg";
import { ReactComponent as Dislike } from "../../assets/icons/dislike.svg";
import { ReactComponent as LikeOutline } from "../../assets/icons/like-outline.svg";
import { ReactComponent as DislikeOutline } from "../../assets/icons/dislike-outline.svg";

import colors from "../../helpers/colors";

import {
  CandidateTriageStatusApprovedByClient,
  CandidateTriageStatusPassedByClient,
  CLIENT_PASS_ON_CANDIDATE_FOR_JOB_MUTATION,
  CLIENT_APPROVE_CANDIDATE_FOR_JOB_MUTATION,
} from "../../graphql/jobs";
import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";
import FormModal from "../FormModal";

export function ClientPassAddActions({
  candidateTriageStatus,
  candidate,
  moblyzeJob,
  refetch,
}) {
  const buttonStyle = {
    cursor: "pointer",
  };

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [note, setNote] = React.useState("");

  const onPass = () => {
    MoblyzeApolloClient.mutate({
      mutation: CLIENT_PASS_ON_CANDIDATE_FOR_JOB_MUTATION,
      variables: {
        moblyzeJobId: moblyzeJob.id,
        candidateUserId: candidate.id,
      },
    })
      .then((result) => {
        if (refetch) {
          refetch();
        }
        toast({
          title: "Passed on candidate",
          status: "success",
        });

        onClose();
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };

  const onApproveSubmit = () => {
    MoblyzeApolloClient.mutate({
      mutation: CLIENT_APPROVE_CANDIDATE_FOR_JOB_MUTATION,
      variables: {
        moblyzeJobId: moblyzeJob.id,
        candidateUserId: candidate.id,
        note,
      },
    })
      .then((result) => {
        if (refetch) {
          refetch();
        }
        toast({
          title: "Approved candidate",
          status: "success",
        });

        onClose();
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };

  const onApprovePressed = () => {
    onOpen();
  };

  return (
    <Flex gap="24px">
      {candidateTriageStatus === CandidateTriageStatusApprovedByClient ? (
        <Icon
          fontSize="48px"
          as={Like}
          style={buttonStyle}
          className="hoverOpacity"
        />
      ) : (
        <Icon
          fontSize="48px"
          as={LikeOutline}
          onClick={onApprovePressed}
          style={buttonStyle}
          className="hoverOpacity"
        />
      )}
      {candidateTriageStatus === CandidateTriageStatusPassedByClient ? (
        <Icon
          fontSize="48px"
          as={Dislike}
          style={buttonStyle}
          className="hoverOpacity"
        />
      ) : (
        <Icon
          fontSize="48px"
          as={DislikeOutline}
          onClick={onPass}
          style={buttonStyle}
          className="hoverOpacity"
        />
      )}

      <FormModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="The recruiter will be notified that you approve this candidate.

        Add a note for the recruiter to fast-track the hiring process."
        onSubmit={onApproveSubmit}
        submitTitle="Yes, Move Forward"
      >
        <FormControl>
          <FormLabel fontSize="sm">Add a note for the recruiter</FormLabel>
          <Textarea
            mb="8"
            color={colors.charcoal}
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
        </FormControl>
      </FormModal>
    </Flex>
  );
}
