import React from "react";
import {
  Flex,
  Stack,
  Button,
  Spinner,
  Input,
  useToast,
  FormLabel,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import {
  CREATE_CLIENT_ORG_MUTATION,
  UPDATE_CLIENT_ORG_MUTATION,
  UPLOAD_CLIENT_ORG_LOGO_MUTATION,
} from "../../../graphql/clients";
import MoblyzeApolloClient from "../../../services/MoblyzeApolloClient";
import PageTitle from "../../../components/PageTitle";
import WithQuery from "../../../components/WithQuery";
import { CLIENT_ORG_QUERY } from "../../../graphql/clients";
import { CLIENT_DETAILS_PATH } from "../../../routes";

import { ImageFilePicker } from "../../../components/Filepicker";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

function ClientEditCreatePage({ data, error, loading, currentUser }) {
  const toast = useToast();
  const navigate = useNavigate();

  const clientOrg = data?.getClientOrganization;

  const isCreate = !clientOrg;

  const [companyName, setCompanyName] = React.useState(clientOrg?.name || "");

  const [websiteUrl, setWebsiteUrl] = React.useState(
    clientOrg?.websiteUrl || ""
  );

  const [imageFile, setImageFile] = React.useState(null);

  const [submitting, setSubmitting] = React.useState(false);

  const pageTitle = isCreate ? "Add Client Company" : "Edit Client Company";

  // useEffect to set the form values
  React.useEffect(() => {
    if (clientOrg) {
      setCompanyName(clientOrg.name);
      setWebsiteUrl(clientOrg.websiteUrl);
    }
  }, [clientOrg]);

  if (error) {
    return null;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const uploadClientLogo = async (clientOrgId) => {
    if (!imageFile) {
      return;
    }

    const r = await MoblyzeApolloClient.mutate({
      mutation: UPLOAD_CLIENT_ORG_LOGO_MUTATION,
      variables: { clientOrganizationId: clientOrgId, imageFile },
    });

    toast({
      title: "Client logo uploaded",
      status: "success",
    });
  };

  const createClientOrgAndUploadLogo = async () => {
    try {
      if (!companyName) {
        alert("Error", "Please enter a company name.");
        return;
      }

      setSubmitting(true);

      const r = await MoblyzeApolloClient.mutate({
        mutation: CREATE_CLIENT_ORG_MUTATION,
        variables: { name: companyName, websiteUrl },
      });

      const clientOrgId = r.data.createClientOrganization.id;

      await uploadClientLogo(clientOrgId);

      toast({
        title: "Client created",
        status: "success",
      });

      // navigate to new client
      navigate(CLIENT_DETAILS_PATH.replace(":id", clientOrgId));
    } catch (e) {
      console.log("Create client org error", e);
      alert("Error", "There was an error creating the client.");
    } finally {
      setSubmitting(false);
    }
  };

  const updateClientOrgAndUploadLogo = async () => {
    try {
      if (!companyName) {
        alert("Error", "Please enter a company name.");
        return;
      }

      const r = await MoblyzeApolloClient.mutate({
        mutation: UPDATE_CLIENT_ORG_MUTATION,
        variables: {
          clientOrganizationId: clientOrg.id,
          name: companyName,
          websiteUrl,
        },
      });

      await uploadClientLogo(clientOrg.id);

      toast({
        title: "Client updated",
        status: "success",
      });

      // navigate to new client
      navigate(CLIENT_DETAILS_PATH.replace(":id", clientOrg.id));
    } catch (e) {
      console.log("Update client org error", e);
      alert("Error", "There was an error updating the client.");
    }
  };

  const onSaveButtonPress = () => {
    if (isCreate) {
      createClientOrgAndUploadLogo();
    } else {
      updateClientOrgAndUploadLogo();
    }
  };

  return (
    <Flex
      direction="column"
      mr={{
        base: "0px",
        md: "40%",
        lg: "50%",
      }}
    >
      {loading && <Spinner size="xl" color="primary.500" />}
      <PageTitle title={pageTitle} />
      <Stack mt="8">
        <FormLabel fontSize="md" color="white">
          Client Company
        </FormLabel>

        <Input
          mb="5"
          placeholder="Add company name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />

        <FormLabel fontSize="md" color="white">
          Website URL
        </FormLabel>

        <Input
          mb="5"
          placeholder="Add website url"
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />

        <FormLabel fontSize="md" color="white">
          {isCreate ? "" : "New "}Client Logo
        </FormLabel>

        <ImageFilePicker
          onChange={(file) => setImageFile(file)}
          name="logoUrl"
          setImageValue={setImageFile}
        />

        <Flex align="center">
          <Button
            width="100%"
            onClick={onSaveButtonPress}
            disabled={submitting}
            isLoading={submitting}
          >
            Save
          </Button>
        </Flex>
      </Stack>
    </Flex>
  );
}

function PageComponent({ children }) {
  const { id } = useParams();

  if (!id) {
    return <ClientEditCreatePage data={{}} error={null} loading={false} />;
  }

  return WithQuery(
    ClientEditCreatePage,
    CLIENT_ORG_QUERY,
    { id },
    {
      children,
    }
  )();
}

export default WithAppLayout(PageComponent);
