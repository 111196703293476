import React from "react";
import { useParams } from "react-router-dom";
import { Flex, Box } from "@chakra-ui/react";

import WithAppLayout from "../../../components/WithAppLayout";
import WithQuery from "../../../components/WithQuery";

import { CURATE_JOB_PAGE_QUERY } from "../../../graphql/jobs_admin";

import { CreateEditJobForm } from "./CreateEditJob";

function BullhornJobDisplay({ loading, data, error, refetch, currentUser }) {
  if (loading || error) return null;

  const bullhornJob = data?.bullhornJob;

  const {
    bullhornId,

    jobInformation,

    vertical,
    jobTitle,
    industries,
    jobCategory,
    requiredSkills,

    client,
    contact,

    employmentType,
    numOpenings,

    address,
    city,
    county,
    postcode,
    country,

    region,

    currency,
    payRate,
    payUnit,
    salaryHigh,
    salaryLow,

    jobDuration,
    startDate,
    scheduledEndDate,
  } = bullhornJob || {};

  const displayJob = {
    bullhornId,

    jobInformation,

    vertical,
    jobTitle,
    industries,
    jobCategory,
    requiredSkills,

    client,
    contact,

    employmentType,
    numOpenings,

    address,
    city,
    county,
    postcode,
    country,

    region,

    currency,
    payRate,
    payUnit,
    salaryHigh,
    salaryLow,

    jobDuration,
    startDate,
    scheduledEndDate,
  };

  return (
    // make this scroll independently of the page
    <Box
      style={{
        overflowY: "scroll",
        height: "100%",
      }}
    >
      {/* iterate over values, display them */}
      {Object.entries(displayJob || {}).map(([key, value]) => (
        <Box key={key} mb={3}>
          <Box as="span" fontWeight="bold">
            {key}:
          </Box>
          <br />
          <Box as="span">{value}</Box>
        </Box>
      ))}
    </Box>
  );
}

function CurateJobPage({ loading, data, error, refetch, currentUser }) {
  console.log("CurateJobPage", { loading, data, error, refetch, currentUser });
  return (
    <Flex
      direction={{
        base: "row",
      }}
      flex={1}
    >
      <Flex direction="column" flex={1}>
        <CreateEditJobForm
          loading={loading}
          data={data}
          error={error}
          refetch={refetch}
          currentUser={currentUser}
          isCuration
        />
      </Flex>
      <Flex direction="column" flex={1} flexWrap={"wrap"} pl={20} pt={20}>
        <BullhornJobDisplay
          loading={loading}
          data={data}
          error={error}
          refetch={refetch}
          currentUser={currentUser}
        />
      </Flex>
    </Flex>
  );
}

function PageComponent({ children }) {
  const { id } = useParams();

  return WithQuery(
    CurateJobPage,
    CURATE_JOB_PAGE_QUERY,
    { bullhornJobId: id },
    {
      children,
    }
  )();
}

export default WithAppLayout(PageComponent);
