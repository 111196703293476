import React from "react";
import { Box, Center, Flex, Link } from "@chakra-ui/react";

import AuthLayout from "../../layouts/AuthLayout";
import ClaimInviteForm from "../../components/ClaimInviteForm";
import { GET_PUBLIC_INVITE_INFO_QUERY } from "../../graphql/auth";
import WithQuery from "../../components/WithQuery";

import { LOGIN_PATH } from "../../routes";

function ClaimInvite({ data, error, loading, refetch, token }) {
  const inviteToken = token;

  // show error if inviteToken is not present
  if (loading) {
    return <AuthLayout />;
  }

  if (!inviteToken || error) {
    return (
      <AuthLayout>
        <Center flex="1">
          <Flex
            px={{ base: "4", md: "8" }}
            py={{ base: "12", md: "48" }}
            width="full"
            maxW="md"
            direction="column"
            align="center"
            textAlign={"center"}
          >
            <Box
              fontSize="3xl"
              fontWeight="bold"
              lineHeight="tight"
              textAlign="center"
              mb="8"
            >
              Invalid invite link
            </Box>
            <Box fontSize="lg" lineHeight="tight" textAlign="center" mb="8">
              Your invite link may have expired, or have already been claimed.
              <br />
              <br />
              Please contact the person who sent you this link to get a new
              invitation.
            </Box>

            <Link
              fontSize="sm"
              mt={10}
              color="colors.white"
              textDecoration={"underline"}
              textDecorationColor={"colors.white"}
              href={LOGIN_PATH}
            >
              Already have an account? Login
            </Link>
          </Flex>
        </Center>
      </AuthLayout>
    );
  }

  const invitation = data.getPublicInviteInfo;
  const inviteFirstName = invitation.firstName;
  const inviteEmail = invitation.email;

  return (
    <AuthLayout>
      <Center flex="1">
        <ClaimInviteForm
          px={{ base: "4", md: "8" }}
          py={{ base: "12", md: "48" }}
          width="full"
          maxW="md"
          inviteFirstName={inviteFirstName}
          inviteToken={inviteToken}
          inviteEmail={inviteEmail}
        />
      </Center>
    </AuthLayout>
  );
}

function PageComponent({ children }) {
  // Get token from url query params

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  return WithQuery(
    ClaimInvite,
    GET_PUBLIC_INVITE_INFO_QUERY,
    { inviteToken: token },
    {
      children,
      token,
    }
  )();
}

export default PageComponent;
