import React from "react";
import { Flex } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import WithQuery from "../../../components/WithQuery";
import { CLIENT_DETAILS_PAGE_QUERY } from "../../../graphql/clients";
import ClientHeader from "../../../components/ClientHeader";
import PageTableWithSearch from "../../../components/PageTableWithSearch/PageTableWithSearch";
import { jobsColumns } from "../../../components/PageTableWithSearch/tableDataHelper";
import { JOB_DETAILS_PATH } from "../../../routes";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

function ClientDetailPage({ data, error, loading, refetch, currentUser }) {
  const clientOrg = data?.getClientOrganization;
  const navigate = useNavigate();

  if (error) {
    return null;
  }

  if (!clientOrg) {
    return null;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Flex h="100%" direction="column" flex="1">
      <ClientHeader clientOrg={clientOrg} />

      <PageTableWithSearch
        title="Jobs"
        columns={jobsColumns}
        data={data?.getJobsForClientOrganization || []}
        onClickRow={(moblyzeJob) => {
          navigate(JOB_DETAILS_PATH.replace(":id", moblyzeJob.id));
        }}
      />
    </Flex>
  );
}

function PageComponent({ children }) {
  const { id } = useParams();

  return WithQuery(
    ClientDetailPage,
    CLIENT_DETAILS_PAGE_QUERY,
    { id },
    {
      children,
    }
  )();
}

export default WithAppLayout(PageComponent, {
  showGradientBackground: true,
});
