import { Box, Container, HStack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import jabPBMLogo from "../assets/img/jabPBMLogo.png";
import moblyzeLogo from "../assets/img/moblyzeLogo.png";

import { ACCOUNT_PATH } from "../routes";

export default function Navbar({ currentUser }) {
  const isJabAgency =
    currentUser?.clientOrganization?.agencySlugId === "JAB" ||
    currentUser?.recruiterAgencySlugId === "JAB";

  return (
    <Box
      bg={"var(--gray-1000, #1D1D1D)"}
      borderBottom={
        "1px solid var(--white-alpha-500, rgba(255, 255, 255, 0.36))"
      }
    >
      <Container py="4" px="6" maxW="auto">
        <HStack justify="space-between">
          <NavLink to={"/"}>
            {!!currentUser && (
              <img
                src={isJabAgency ? jabPBMLogo : moblyzeLogo}
                alt="Logo"
                style={{ height: "52px" }}
              />
            )}
          </NavLink>

          <NavLink
            to={ACCOUNT_PATH}
            style={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Account Settings
          </NavLink>
        </HStack>
      </Container>
    </Box>
  );
}
