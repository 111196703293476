import React from "react";
import { Link, Box, Flex, Icon, Text } from "@chakra-ui/react";

import boatImg from "../assets/img/boat.jpg";

export default function AuthLayout({ children }) {
  return (
    <Flex minH={{ base: "auto", md: "100vh" }} h="max-content">
      <Box
        flex="1"
        display={{ base: "none", md: "block" }}
        bgImage={{
          base: "none",
          md: `url(${boatImg})`,
        }}
        bgSize="cover"
      />
      {children}
    </Flex>
  );
}
