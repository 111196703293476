import { gql } from "@apollo/client";

export const UPLOAD_VESSEL_IMAGE_MUTATION = gql`
  mutation UploadVesselImage($vesselId: ID!, $imageFile: Upload!) {
    uploadVesselImage(vesselId: $vesselId, imageFile: $imageFile) {
      id
    }
  }
`;

export const CREATE_VESSEL_MUTATION = gql`
  mutation CreateVessel(
    $name: String!
    $subtitle: String
    $flag: String
    $ageDescription: String
    $wifiSpeedDescription: String
    $isSmoking: String
    $totalCrew: String
    $vesselUrl: String
  ) {
    createVessel(
      input: {
        name: $name
        subtitle: $subtitle
        flag: $flag
        ageDescription: $ageDescription
        wifiSpeedDescription: $wifiSpeedDescription
        isSmoking: $isSmoking
        totalCrew: $totalCrew
        vesselUrl: $vesselUrl
      }
    ) {
      id
      name
      subtitle
      flag
      ageDescription
      wifiSpeedDescription
      isSmoking
      totalCrew
      vesselUrl
    }
  }
`;
