import { Controller } from "react-hook-form";
import { Select } from "chakra-react-select";

export function MultiSelectHook({
  control,
  options,
  inputName,
  required = false,
  disabled = false,
}) {
  return (
    <Controller
      control={control}
      name={inputName}
      rules={{ required }}
      options={options}
      disabled={disabled}
      render={({ field: { onChange, onBlur, value, name, ref, disabled } }) => {
        // console.log("value", value);
        return (
          <Select
            isMulti
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            isDisabled={disabled}
            colorScheme="blue"
            variant="outlineDark"
            options={options}
          />
        );
      }}
    />
  );
}

export function SelectHook({
  control,
  options,
  inputName,
  required = false,
  disabled = false,
}) {
  return (
    <Controller
      control={control}
      name={inputName}
      rules={{ required }}
      options={options}
      disabled={disabled}
      render={({ field: { onChange, onBlur, value, name, ref, disabled } }) => {
        // console.log("value", value);
        return (
          <Select
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            colorScheme="blue"
            variant="outlineDark"
            isDisabled={disabled}
            options={options}
          />
        );
      }}
    />
  );
}
