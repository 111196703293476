import { theme } from "@chakra-ui/pro-theme";
import { extendTheme } from "@chakra-ui/react";

import colors from "./helpers/colors";

const proTheme = extendTheme(theme);
const extendedConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
  colors: {
    ...proTheme.colors,
    brand: {
      50: "#f4f8fb",
      100: "#d2e2f1",
      200: "#abc9e5",
      300: "#7baad6",
      400: "#6099cd",
      500: "#3a81c1",
      600: "#266cad",
      700: "#1e578b",
      800: "#1a4a75",
      900: "#123555",
    },
    primary: {
      50: "#f4f8fb",
      100: "#d2e2f1",
      200: "#abc9e5",
      300: "#7baad6",
      400: "#6099cd",
      500: "#3a81c1",
      600: "#266cad",
      700: "#1e578b",
      800: "#1a4a75",
      900: "#123555",
    },
    red: {
      50: "#FFE5EC",
      100: "#FFB8CB",
      200: "#FF8AA9",
      300: "#FF5C88",
      400: "#FF2E66",
      500: "#FF0044",
      600: "#CC0037",
      700: "#990029",
      800: "#66001B",
      900: "#33000E",
    },
    yellow: {
      50: "#FFF4E5",
      100: "#FFE0B8",
      200: "#FFCC8A",
      300: "#FFB85C",
      400: "#FFA42E",
      500: "#FF9000",
      600: "#CC7300",
      700: "#995600",
      800: "#663A00",
      900: "#331D00",
    },
    blue: {
      50: "#E8EEFD",
      100: "#BED1F8",
      200: "#95B3F4",
      300: "#6B95F0",
      400: "#4278EB",
      500: "#185AE7",
      600: "#1348B9",
      700: "#0E368B",
      800: "#0A245C",
      900: "#05122E",
    },
    green: {
      50: "#E5FFF7",
      100: "#B8FFE7",
      200: "#8AFFD8",
      300: "#5CFFC9",
      400: "#2EFFBA",
      500: "#00FFAA",
      600: "#00CC88",
      700: "#009966",
      800: "#006644",
      900: "#003322",
    },
    gray: {
      50: "#F2F2F2",
      100: "#DBDBDB",
      200: "#C4C4C4",
      300: "#ADADAD",
      400: "#969696",
      500: "#808080",
      600: "#666666",
      700: "#4D4D4D",
      800: "#333333",
      900: "#1A1A1A",
    },
  },
  fonts: {
    heading: "'Hubot Sans', -apple-system, system-ui, sans-serif",
    body: "'Hubot Sans', -apple-system, system-ui, sans-serif",
  },
  styles: {
    global: {
      body: {
        bg: colors.greyDark,
        color: "white",
      },
    },
  },
  radii: {
    none: "0",
    sm: "4px",
    base: "4px",
    md: "4px",
    lg: "4px",
    xl: "4px",
    "2xl": "4px",
    "3xl": "4px",
    full: "4px",
  },
  components: {
    Input: {
      variants: {
        darkMode: {
          field: {
            bg: colors.greyMedium,
          },
        },
        black: {
          field: {
            bg: colors.black,
            color: colors.white,
            _placeholder: {
              color: colors.white,
            },
          },
        },
        light: {
          field: {
            bg: colors.white,
            color: colors.charcoal,
            border: "1px solid",
            borderColor: colors.greyMedium,
          },
        },
        outlineDark: {
          field: {
            bg: colors.greyDark,
            border: "1px solid",
            borderColor: colors.greyMedium,
            _disabled: {
              opacity: 0.8,
            },
          },
        },
      },
      defaultProps: {
        variant: "darkMode",
      },
    },
    NumberInput: {
      variants: {
        darkMode: {
          field: {
            bg: colors.greyMedium,
          },
        },
        black: {
          field: {
            bg: colors.black,
            color: colors.white,
            _placeholder: {
              color: colors.white,
            },
          },
        },
        light: {
          field: {
            bg: colors.white,
            color: colors.charcoal,
            border: "1px solid",
            borderColor: colors.greyMedium,
          },
        },
        outlineDark: {
          field: {
            bg: colors.greyDark,
            border: "1px solid",
            borderColor: colors.greyMedium,
            _disabled: {
              opacity: 0.8,
            },
          },
        },
      },
      defaultProps: {
        variant: "darkMode",
      },
    },
    Textarea: {
      variants: {
        light: {
          bg: colors.white,
          color: colors.charcoal,
          border: "1px solid",
          borderColor: colors.greyMedium,
        },
        outlineDark: {
          bg: colors.greyDark,
          border: "1px solid",
          borderColor: colors.greyMedium,
          _disabled: {
            opacity: 0.8,
          },
        },
      },
    },
    Table: {
      variants: {
        darkMode: {
          th: {
            bg: colors.greyDark,
            borderBottom: "1px solid white",
            borderTop: "none",
          },
          tr: {
            bg: colors.greyDark,
            borderBottom: `1px solid ${colors.greyMedium}`,
            _hover: {
              bg: colors.greyMedium,
            },
          },
          tbody: {
            color: colors.white,
            fontSize: "16px",
            fontWeight: "500",
          },
        },
      },
      defaultProps: {
        variant: "darkMode",
      },
    },
    Menu: {
      variants: {
        darkMode: {
          list: {
            bg: colors.greyDark,
            color: colors.white,
            border: "1px solid",
            borderColor: colors.greyMedium,
            padding: 0,
          },
          button: {
            bg: colors.greyDark,
            color: colors.white,
            _hover: {
              bg: colors.greyMedium,
            },
          },
          item: {
            bg: colors.greyDark,
            color: colors.white,
            _hover: {
              bg: colors.greyMedium,
            },
          },
          divider: {
            bg: colors.greyMedium,
          },
        },
      },
      defaultProps: {
        variant: "darkMode",
      },
    },
    Modal: {
      variants: {
        darkMode: {
          dialog: {
            bg: colors.greyDark,
            color: colors.white,
          },
        },
      },
    },
    Drawer: {
      parts: ["dialog", "header", "body"],
      variants: {
        secondary: {
          dialog: {
            maxW: "1004px",
          },
        },
      },
    },
  },
};

export default extendTheme(extendedConfig, proTheme);
