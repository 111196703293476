import React, { useState } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import moment from "moment";

import { ReactComponent as Pin } from "../assets/icons/pin.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";

import { FiBriefcase as Briefcase } from "react-icons/fi";

import { Icon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { ADD_JOB_PATH, EDIT_CLIENT_PATH } from "../routes";

export default function ClientHeader({ clientOrg }) {
  const navigate = useNavigate();

  if (!clientOrg) {
    return null;
  }

  return (
    <>
      <Flex paddingBottom="34px" align="center">
        <Text
          onClick={() => {
            // if we can naviate to the previous page, do so
            // otherwise, navigate to the home page
            if (window.history.length > 1) {
              navigate(-1);
            } else {
              navigate("/");
            }
          }}
          fontSize="md"
          lineHeight="6"
          fontWeight="normal"
          cursor="pointer"
        >
          <Icon fontSize="10px" as={LeftArrow} /> Back
        </Text>
      </Flex>
      <Flex borderBottom="1px solid #6F6F6F" gap="20px" w="100%" p="26px 0">
        <Image h={150} w={150} borderRadius={150} src={clientOrg?.logoUrl} />
        <Flex flex={1} gap="20px" direction="column" justifyContent="center">
          <Box>
            <Text fontSize="3xl" fontWeight="semibold" lineHeight="7">
              {clientOrg?.name}
            </Text>
          </Box>

          <Flex gap="16px" direction="column">
            <Flex gap="16px">
              <Icon fontSize={20} as={Pin} />
              <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                {clientOrg?.clientOrganizationReport?.locationsDescription}
              </Text>
            </Flex>

            <Flex gap="16px">
              <Flex gap="16px">
                <Icon fontSize={20} as={Briefcase} />
                <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                  {clientOrg?.clientOrganizationReport?.numActiveJobs} Active
                  Jobs
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex alignItems="center" direction="row" gap="16px" h="40px">
          <Button
            minWidth="120px"
            onClick={() => {
              navigate(ADD_JOB_PATH);
            }}
          >
            Add Job
          </Button>
          <Button
            color="whiteAlpha.700"
            _hover={{ bg: "whiteAlpha.50" }}
            variant="outline"
            onClick={() => {
              navigate(EDIT_CLIENT_PATH.replace(":id", clientOrg.id));
            }}
          >
            Edit Client Details
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
