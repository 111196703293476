import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { Login } from "../services/MoblyzeAuth";
import { SUPPORT_EMAIL } from "../helpers/config";
import { AccountTermsText } from "./TermsLinks";

export const SignInForm = (props) => {
  let navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onSubmitButtonPress = () => {
    if (!email || !password) {
      alert("Invalid credentials, please check your login information.");

      return;
    }

    setLoading(true);

    Login({ email, password })
      .then((data) => {
        navigate(`/`);
      })
      .catch(() => {
        setPassword("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack spacing="8" {...props}>
      <Stack spacing="6">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={{ base: "sm", md: "md" }} mb="3">
            Sign In
          </Heading>
          <Text fontSize={{ base: "md", md: "lg" }} fontWeight={600} mb="3">
            Welcome back!
            <br />
            We’re happy to have you here.
          </Text>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="email" fontSize="xs" color={"colors.white"}>
              Email
            </FormLabel>
            <Input
              id="email"
              placeholder="Email"
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password" fontSize="xs" color={"colors.white"}>
              Password
            </FormLabel>
            <Input
              id="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              type="password"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmitButtonPress();
                }
              }}
            />
          </FormControl>
        </Stack>

        <Stack spacing="6" textAlign={"center"} alignItems={"center"}>
          <Button
            onClick={onSubmitButtonPress}
            isLoading={loading}
            colorScheme={"brand"}
            w={"full"}
          >
            Sign In
          </Button>

          <Link
            fontSize="sm"
            mt={6}
            color="colors.white"
            textDecoration={"underline"}
            textDecorationColor={"colors.white"}
            href="/reset"
          >
            Having issues? Forgot password
          </Link>

          <Text fontSize="sm">
            Don't have an account?
            <br />
            <Link
              href={`mailto:${SUPPORT_EMAIL}`}
              color="colors.white"
              textDecoration={"underline"}
              textDecorationColor={"colors.white"}
            >
              Contact Moblyze
            </Link>
          </Text>

          <AccountTermsText />
        </Stack>
      </Stack>
    </Stack>
  );
};
