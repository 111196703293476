import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { GetLocalAuthRole } from "../services/MoblyzeAuth";

export default function ClientRoute(props) {
  const [isClientRole, setIsClientRole] = useState(null);

  // Check auth state on component mount
  useEffect(() => {
    const fetchData = async () => {
      const role = await GetLocalAuthRole();

      setIsClientRole(role === "Client");
    };
    fetchData();
  });

  if (isClientRole == null) {
    return null;
  } else if (isClientRole) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    );
  }
}
