import React from "react";
import { useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTableWithSearch from "../../../components/PageTableWithSearch/PageTableWithSearch";

import { activeJobsColumns } from "../../../components/PageTableWithSearch/tableDataHelper";
import WithQuery from "../../../components/WithQuery";
import { ACTIVE_JOBS_QUERY } from "../../../graphql/jobs";
import { JOB_DETAILS_PATH } from "../../../routes";
import { useHistoryState } from "../../../helpers/hooks";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

function ActiveJobsPageInner({ data, error, loading, refetch, currentUser }) {
  const navigate = useNavigate();

  const [initialFilter, setInitialFilter] = useHistoryState("filter", null);

  if (loading) {
    return <LoadingIndicator />;
  }

  const initialSort = [
    {
      id: "approvedByClientCount",
      desc: true,
    },
    {
      id: "interestedCount",
      desc: true,
    },
  ];

  return (
    <PageTableWithSearch
      title="Active Jobs"
      initialValue={initialFilter}
      columns={activeJobsColumns}
      data={data?.agencyActiveJobs || []}
      onClickRow={(moblyzeJob, search) => {
        // set current page state to the current search filter
        if (search && search !== "") {
          setInitialFilter(search);
        }

        navigate(JOB_DETAILS_PATH.replace(":id", moblyzeJob.id));
      }}
      initialSort={initialSort}
    />
  );
}

function PageComponent({ children }) {
  return WithQuery(ActiveJobsPageInner, ACTIVE_JOBS_QUERY, null, {
    children,
  })();
}

export default WithAppLayout(PageComponent);
