import { gql } from "@apollo/client";

import { MOBLYZE_JOB_FRAGMENT } from "./jobs";

export const BULLHORN_JOB_FRAGMENT = gql`
  fragment bullhornJobFragment on BullhornJob {
    id
    bullhornId
    jobTitle
    client
    employmentType
    openClosed
    status
    numOpenings
    dateAdded
    vertical
    address
    city
    county
    postcode
    country
    contact
    jobInformation
    locationRequirements
    payRate
    payUnit
    salaryHigh
    salaryLow
    billRate
    industries
    industriesCount
    jobCategory
    jobCategoryCount
    jobDuration
    publishedDescription
    region
    requiredSkills
    startDate
    worksite
    currency

    moblyzeJobShell {
      bullhornId

      category
      title
      employmentType
      numOpenings

      employerContactName
      employerContactEmail
      employerContactPhone

      agencyContactName
      agencyContactEmail
      agencyContactPhone

      location

      duration
      startDate

      requirementsDescription
      responsibilitiesDescription
      otherDescription

      vesselId

      hiredInstructions
      siteAddress
    }
  }
`;

export const JOB_CREATE_PAGE_QUERY = gql`
  {
    getClientOrganizations {
      id
      name
    }

    candidateRoles {
      id
      name
    }

    candidateWorkLocations {
      id
      name
    }

    getVessels {
      id
      name
      subtitle
      flag
      ageDescription
      wifiSpeedDescription
      isSmoking
      totalCrew
      vesselUrl
      imageUrl
    }
  }
`;

export const JOB_EDIT_PAGE_QUERY = gql`
  ${MOBLYZE_JOB_FRAGMENT}
  query JobEditPageQuery($id: ID!) {
    moblyzeJob(id: $id) {
      ...MoblyzeJobFragment
    }

    getClientOrganizations {
      id
      name
    }

    candidateRoles {
      id
      name
    }

    candidateWorkLocations {
      id
      name
    }

    getVessels {
      id
      name
      subtitle
      flag
      ageDescription
      wifiSpeedDescription
      isSmoking
      totalCrew
      vesselUrl
      imageUrl
    }
  }
`;

export const UNCURATED_JOBS_QUERY = gql`
  ${BULLHORN_JOB_FRAGMENT}
  query UncuratedJobsQuery {
    agencyUncuratedJobs {
      ...bullhornJobFragment
    }
  }
`;

export const CURATE_JOB_PAGE_QUERY = gql`
  ${BULLHORN_JOB_FRAGMENT}
  query UncuratedJobPageQuery($bullhornJobId: ID!) {
    bullhornJob(bullhornJobId: $bullhornJobId) {
      ...bullhornJobFragment
    }

    getClientOrganizations {
      id
      name
    }

    candidateRoles {
      id
      name
    }

    candidateWorkLocations {
      id
      name
    }

    getVessels {
      id
      name
      subtitle
      flag
      ageDescription
      wifiSpeedDescription
      isSmoking
      totalCrew
      vesselUrl
      imageUrl
    }
  }
`;

export const CREATE_JOB_MUTATION = gql`
  mutation CreateJob(
    $bullhornId: String
    $category: String
    $title: String
    $employmentType: MoblyzeJobType
    $numOpenings: Int
    $clientOrganizationId: ID
    $employerContactName: String
    $employerContactEmail: String
    $employerContactPhone: String
    $agencyContactName: String
    $agencyContactEmail: String
    $agencyContactPhone: String
    $location: String
    $duration: String
    $startDate: Time
    $requirementsDescription: String
    $responsibilitiesDescription: String
    $vesselId: ID
    $hiredInstructions: String
    $siteAddress: String
    $candidateRoleIds: [ID!]
    $candidateWorkLocationIds: [ID!]
    $payRateValue: Float
    $payFrequency: MoblyzeJobPayFrequency
    $payCurrency: MoblyzeJobPayCurrency
  ) {
    createJob(
      input: {
        bullhornId: $bullhornId
        category: $category
        title: $title
        employmentType: $employmentType
        numOpenings: $numOpenings
        clientOrganizationId: $clientOrganizationId
        employerContactName: $employerContactName
        employerContactEmail: $employerContactEmail
        employerContactPhone: $employerContactPhone
        agencyContactName: $agencyContactName
        agencyContactEmail: $agencyContactEmail
        agencyContactPhone: $agencyContactPhone
        location: $location
        duration: $duration
        startDate: $startDate
        requirementsDescription: $requirementsDescription
        responsibilitiesDescription: $responsibilitiesDescription
        vesselId: $vesselId
        hiredInstructions: $hiredInstructions
        siteAddress: $siteAddress
        candidateRoleIds: $candidateRoleIds
        candidateWorkLocationIds: $candidateWorkLocationIds
        payRateValue: $payRateValue
        payFrequency: $payFrequency
        payCurrency: $payCurrency
      }
    ) {
      id
    }
  }
`;

export const UPLOAD_JOB_COVER_IMAGE_MUTATION = gql`
  mutation UploadJobCoverImage($moblyzeJobId: ID!, $imageFile: Upload!) {
    uploadJobCoverImage(moblyzeJobId: $moblyzeJobId, imageFile: $imageFile) {
      id
    }
  }
`;

export const UPDATE_JOB_MUTATION = gql`
  mutation updateJobDetails(
    $bullhornId: String
    $moblyzeJobId: ID!
    $category: String
    $title: String
    $employmentType: MoblyzeJobType
    $numOpenings: Int
    $clientOrganizationId: ID
    $employerContactName: String
    $employerContactEmail: String
    $employerContactPhone: String
    $agencyContactName: String
    $agencyContactEmail: String
    $agencyContactPhone: String
    $location: String
    $duration: String
    $startDate: Time
    $requirementsDescription: String
    $responsibilitiesDescription: String
    $vesselId: ID
    $hiredInstructions: String
    $siteAddress: String
    $candidateRoleIds: [ID!]
    $candidateWorkLocationIds: [ID!]
    $payRateValue: Float
    $payFrequency: MoblyzeJobPayFrequency
    $payCurrency: MoblyzeJobPayCurrency
  ) {
    updateJobDetails(
      moblyzeJobId: $moblyzeJobId
      input: {
        category: $category
        title: $title
        employmentType: $employmentType
        numOpenings: $numOpenings
        clientOrganizationId: $clientOrganizationId
        employerContactName: $employerContactName
        employerContactEmail: $employerContactEmail
        employerContactPhone: $employerContactPhone
        agencyContactName: $agencyContactName
        agencyContactEmail: $agencyContactEmail
        agencyContactPhone: $agencyContactPhone
        location: $location
        duration: $duration
        startDate: $startDate
        requirementsDescription: $requirementsDescription
        responsibilitiesDescription: $responsibilitiesDescription
        vesselId: $vesselId
        hiredInstructions: $hiredInstructions
        siteAddress: $siteAddress
        candidateRoleIds: $candidateRoleIds
        candidateWorkLocationIds: $candidateWorkLocationIds
        payRateValue: $payRateValue
        payFrequency: $payFrequency
        payCurrency: $payCurrency
        bullhornId: $bullhornId
      }
    ) {
      id
    }
  }
`;

export const IMPORT_BULLHORN_JOBS_MUTATION = gql`
  ${BULLHORN_JOB_FRAGMENT}
  mutation ImportBullhornJobs($csvFile: Upload!) {
    importBullhornJobs(csvFile: $csvFile) {
      ...bullhornJobFragment
    }
  }
`;
