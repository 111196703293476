import React from "react";
import { Navigate } from "react-router-dom";

import WithAppLayout from "../../components/WithAppLayout";
import { CLIENT_DASHBOARD_PATH, UNCURATED_JOBS_PATH } from "../../routes";
import { LoadingIndicator } from "../../components/LoadingIndicator";

function DashboardPage({ loading, refetch, currentUser }) {
  if (loading) {
    return <LoadingIndicator />;
  }

  // Auto redirect to uncurated jobs page if recruiter
  if (currentUser?.role === "Recruiter") {
    return (
      <Navigate
        to={{
          pathname: UNCURATED_JOBS_PATH,
        }}
      />
    );
  }

  // Auto redirect to client dashboard page if client
  if (currentUser?.role === "Client") {
    return (
      <Navigate
        to={{
          pathname: CLIENT_DASHBOARD_PATH,
        }}
      />
    );
  }

  return null;
}

export default WithAppLayout(DashboardPage);
