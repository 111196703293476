import React from "react";
import { Center } from "@chakra-ui/react";

import AuthLayout from "../../layouts/AuthLayout";
import { SignInForm } from "../../components/SignInForm";

function SignIn() {
  return (
    <AuthLayout>
      <Center flex="1">
        <SignInForm
          px={{ base: "4", md: "8" }}
          py={{ base: "12", md: "48" }}
          width="full"
          maxW="md"
        />
      </Center>
    </AuthLayout>
  );
}

export default SignIn;
