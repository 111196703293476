// -- Public routes
export const LOGIN_PATH = "/login";
export const RESET_PASSWORD_PATH = "/reset";
export const RESET_COMPLETE_PASSWORD_PATH = "/reset-complete";
export const CLAIM_INVITE_PATH = "/claim-invite";

// -- Private routes
export const DASHBOARD_PATH = "/dashboard";
export const ACCOUNT_PATH = "/account";

// Recruiter routes
export const UNCURATED_JOBS_PATH = "/agency/jobs/uncurated";
export const ACTIVE_JOBS_PATH = "/agency/jobs/active";
export const ARCHIVED_JOBS_PATH = "/agency/jobs/archived";
export const ADD_JOB_PATH = "/agency/jobs/add";
export const EDIT_JOB_PATH = "/agency/jobs/:id/edit";
export const CURATE_JOB_PATH = "/agency/bullhornJobs/:id/curate";
export const JOB_DETAILS_PATH = "/agency/jobs/:id";
export const IMPORT_JOBS_PATH = "/agency/jobs/import";

export const ALL_CLIENTS_PATH = "/agency/clients";
export const ADD_CLIENT_PATH = "/agency/clients/add";
export const EDIT_CLIENT_PATH = "/agency/clients/:id/edit";
export const CLIENT_DETAILS_PATH = "/agency/clients/:id";

export const ALL_RECRUITERS_PATH = "/agency/recruiters";

export const ALL_CANDIDATES_PATH = "/agency/candidates";
export const EDIT_CANDIDATE_PATH = "/agency/candidates/:id/edit";
export const INVITE_CANDIDATE_PATH = "/agency/candidates/invite";

// Client routes
export const CLIENT_DASHBOARD_PATH = "/client/dashboard";
export const CLIENT_SHORTLIST_PATH = "/client/shortlist/:id";
