import React, { useState } from "react";
import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

import DataTable from "./DataTable";
import PageTitle from "../PageTitle";

const PageTableWithSearch = ({
  title,
  columns,
  data,
  onClickRow,
  initialSort,
  initialValue,
}) => {
  const [searchInput, setSearchInput] = useState(initialValue);

  return (
    <Flex flex="1">
      <Stack spacing="1" flex="1">
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          justify="space-between"
          mb={8}
          mt={8}
        >
          <PageTitle title={title} />
          <InputGroup maxW="xs">
            <Input
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              variant="black"
            />
            <InputRightElement pointerEvents="none">
              <Icon as={FiSearch} color="white" boxSize="5" />
            </InputRightElement>
          </InputGroup>
        </Stack>

        <Flex>
          <DataTable
            searchInput={searchInput}
            columns={columns}
            data={data}
            onClickRow={onClickRow}
            initialSort={initialSort}
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

export default PageTableWithSearch;
