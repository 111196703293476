import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import WithAppLayout from "../../../components/WithAppLayout";
import { AGENCY_JOB_DETAILS_QUERY } from "../../../graphql/jobs";
import WithQuery from "../../../components/WithQuery";
import { useParams } from "react-router-dom";

import JobHeader from "../../../components/JobHeader";
import AgencyShortlist from "../../../components/Shortlist/AgencyShortlist";
import colors from "../../../helpers/colors";

import { AgencyReactivateJobAction } from "../../../components/AgencyJobActions";

function JobDetailsInner({ data, error, loading, refetch, currentUser }) {
  if (loading || !data) {
    return null;
  }

  const {
    moblyzeJob,
    agencyCandidateTriageInterestedCandidates,
    agencyCandidateTriageShortlistedCandidates,
    agencyCandidateTriageApprovedCandidates,
    agencyCandidateTriageHiredCandidates,
  } = data || {};

  const isArchived = moblyzeJob.status === "Closed";

  const renderBanner = () => {
    if (isArchived) {
      return (
        <>
          <Flex
            bg={colors.red}
            h="40px"
            align="center"
            justify="center"
            position="absolute"
            top="0"
            left="0"
            right="0"
            zIndex="1"
          >
            <Text fontSize="md" lineHeight="6" fontWeight="normal">
              This job is archived.{" "}
              <AgencyReactivateJobAction moblyzeJob={moblyzeJob} />
            </Text>
          </Flex>
          <Flex h="20px" />
        </>
      );
    }

    return null;
  };

  return (
    <>
      {renderBanner()}
      <Flex h="100%" direction="column">
        <JobHeader moblyzeJob={moblyzeJob} isAgency isArchived={isArchived} />
        <AgencyShortlist
          moblyzeJob={moblyzeJob}
          agencyCandidateTriageInterestedCandidates={
            agencyCandidateTriageInterestedCandidates
          }
          agencyCandidateTriageShortlistedCandidates={
            agencyCandidateTriageShortlistedCandidates
          }
          agencyCandidateTriageApprovedCandidates={
            agencyCandidateTriageApprovedCandidates
          }
          agencyCandidateTriageHiredCandidates={
            agencyCandidateTriageHiredCandidates
          }
          isArchived={isArchived}
          refetch={refetch}
        />
      </Flex>
    </>
  );
}

function PageComponent({ children }) {
  const { id } = useParams();

  return WithQuery(
    JobDetailsInner,
    AGENCY_JOB_DETAILS_QUERY,
    { id },
    {
      children,
    }
  )();
}

export default WithAppLayout(PageComponent);
