import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import air from "../../../assets/gif/air.gif";
import Carousel from "../../../components/Carousel";
import JobListItem from "../../../components/Shortlist/JobListItem";

import WithAppLayout from "../../../components/WithAppLayout";

import WithQuery from "../../../components/WithQuery";
import { CLIENT_ACTIVE_JOBS_QUERY } from "../../../graphql/jobs";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

function ClientDashboardPage({ loading, data, error, refetch, currentUser }) {
  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    console.log(error);
  }

  const clientOrg = currentUser?.clientOrganization;

  const jobs = data?.clientActiveJobs;

  return (
    <Box>
      <Flex marginBottom="24px" alignItems="center" gap="22px">
        {!!clientOrg?.logoUrl && (
          <Box
            w={68}
            h={68}
            borderRadius={250}
            background="#FFFFFF"
            overflow="hidden"
          >
            <Flex h="100%" justifyContent="center" align="center">
              <Image src={clientOrg?.logoUrl} />
            </Flex>
          </Box>
        )}
        <Text fontSize="2xl" fontWeight="semibold" lineHeight="10">
          Your Jobs
        </Text>
      </Flex>
      <Flex gap="50px">
        <Flex flex={1} w="100%" direction="column" gap="20px">
          {jobs?.map((job) => (
            <JobListItem key={job.id} moblyzeJob={job} />
          ))}

          {/* show empty message */}
          {!jobs?.length && (
            <Flex
              flex={1}
              w="100%"
              h="100%"
              justifyContent="center"
              align="center"
              direction="column"
              gap="20px"
            >
              <Text fontSize="2xl" fontWeight="semibold" lineHeight="10">
                You have no published jobs to review.
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                Once your jobs are published, you will see them here.
              </Text>
            </Flex>
          )}
        </Flex>
        <Carousel
          data={[
            {
              title: "Your Jobs",
              description:
                "Recruiters are working hard to find the perfect candidates for you jobs. Your jobs will show up here once the recruiter starts building the shortlist.",
              imgPath: air,
            },
            {
              title: "Managing Your Shortlist",
              description:
                "Once you have the shortlist, you will review the candidates and choose which ones you like and which ones you want to pass on.",
              imgPath: air,
            },
            {
              title: "Your New Hires",
              description:
                "After managing your shortlist, the recruiter will connect with you and the candidates offline to figure out the best fit.",
              imgPath: air,
            },
          ]}
        />
      </Flex>
    </Box>
  );
}

function PageComponent() {
  return WithQuery(ClientDashboardPage, CLIENT_ACTIVE_JOBS_QUERY)();
}

export default WithAppLayout(PageComponent);
