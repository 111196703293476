import moment from "moment";

export const LoginErrorMsg =
  "There was an error logging in, please check your email and password and try again.";

export const formatStartDate = (date) => {
  if (!date) {
    return "";
  }

  const m = moment(date);

  return m.format("MMM D, YYYY");
};

export const formatPayFrequency = (payFrequency) => {
  if(payFrequency === 'Daily') return 'Day';
  if(payFrequency === 'Monthly') return 'Month';
  if(payFrequency === 'Yearly') return 'Year';
  return payFrequency;
}