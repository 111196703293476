import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Flex, useToast, Box } from "@chakra-ui/react";
import { sortBy } from "lodash";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTitle from "../../../components/PageTitle";
import WithQuery from "../../../components/WithQuery";

import {
  CREATE_JOB_MUTATION,
  JOB_CREATE_PAGE_QUERY,
  UPLOAD_JOB_COVER_IMAGE_MUTATION,
  UPDATE_JOB_MUTATION,
  JOB_EDIT_PAGE_QUERY,
} from "../../../graphql/jobs_admin";

import MoblyzeApolloClient from "../../../services/MoblyzeApolloClient";
import { JOB_DETAILS_PATH } from "../../../routes";
import { IsProduction } from "../../../helpers/config";
import { usePrevious } from "../../../helpers/hooks";

import {
  BasicInput,
  ImageUpload,
  MultiSelectInput,
  SelectInput,
  SectionTitle,
  Spacer,
} from "../../../components/HookFormsInputs";
import AddVesselModal from "../../../components/AddVesselModal";

const employmentTypeOptions = [
  { value: "FullTime", label: "Full Time" },
  { value: "PartTime", label: "Part Time" },
  { value: "Contract", label: "Contract" },
];

const payFrequencyOptions = [
  { value: "Hourly", label: "Per Hour" },
  { value: "Daily", label: "Per Day" },
  { value: "Weekly", label: "Per Week" },
  { value: "Monthly", label: "Per Month" },
  { value: "Yearly", label: "Per Year" },
];

const payCurrencyOptions = [
  { value: "GBP", label: "GBP" },
  { value: "USD", label: "USD" },
];

const getFormValuesFromMoblyzeJob = ({
  moblyzeJobInner,
  clientOrganizationOptions,
  vesselOptions,
}) => {
  // format startDate as "2023-10-31"
  const startDate = moblyzeJobInner?.startDate
    ? new Date(moblyzeJobInner?.startDate).toISOString().slice(0, 10)
    : null;

  return {
    bullhornId: moblyzeJobInner?.bullhornId,
    payRateValue: moblyzeJobInner?.payRateValue
      ? moblyzeJobInner?.payRateValue.toString()
      : "",
    payFrequencySelectedOption: moblyzeJobInner?.payFrequency
      ? payFrequencyOptions.find(
          (option) => option.value === moblyzeJobInner?.payFrequency
        )
      : null,
    payCurrencySelectedOption: moblyzeJobInner?.payCurrency
      ? payCurrencyOptions.find(
          (option) => option.value === moblyzeJobInner?.payCurrency
        )
      : null,
    clientOrganizationSelectedOption: moblyzeJobInner?.clientOrganizationId
      ? clientOrganizationOptions.find(
          (option) => option.value === moblyzeJobInner?.clientOrganizationId
        )
      : null,
    clientContactName: moblyzeJobInner?.employerContactName,
    clientContactEmail: moblyzeJobInner?.employerContactEmail,
    clientContactPhone: moblyzeJobInner?.employerContactPhone,
    agencyContactName: moblyzeJobInner?.agencyContactName,
    agencyContactEmail: moblyzeJobInner?.agencyContactEmail,
    agencyContactPhone: moblyzeJobInner?.agencyContactPhone,
    jobCategory: moblyzeJobInner?.category,
    jobPositionTitle: moblyzeJobInner?.title,
    jobLocationDescription: moblyzeJobInner?.location,
    duration: moblyzeJobInner?.duration,
    startDate: startDate,
    moreRequirements: moblyzeJobInner?.requirementsDescription,
    keyResponsibilities: moblyzeJobInner?.responsibilitiesDescription,
    numOpenings: moblyzeJobInner?.numOpenings,
    hiredInstructions: moblyzeJobInner?.hiredInstructions,
    siteAddress: moblyzeJobInner?.siteAddress,
    employmentTypeSelectedOption: moblyzeJobInner?.employmentType
      ? employmentTypeOptions.find(
          (option) => option.value === moblyzeJobInner?.employmentType
        )
      : null,
    vesselSelectedOption: moblyzeJobInner?.vesselId
      ? vesselOptions.find(
          (option) => option.value === moblyzeJobInner?.vesselId
        )
      : null,
    jobLocationSelectedOptions: moblyzeJobInner?.workLocations?.map(
      (location) => ({
        value: location.id,
        label: location.name,
      })
    ),
    jobRolesSelectedOptions: moblyzeJobInner?.roles?.map((role) => ({
      value: role.id,
      label: role.name,
    })),
  };
};

export function CreateEditJobForm({
  loading,
  data,
  error,
  refetch,
  currentUser,
  isCuration,
}) {
  const isCreate = !data?.moblyzeJob || isCuration;
  const moblyzeJob = isCuration
    ? data?.bullhornJob?.moblyzeJobShell
    : data?.moblyzeJob;

  const prevProps = usePrevious({ moblyzeJob });

  const toast = useToast();

  const navigate = useNavigate();
  const clientOrganizationOptions = data?.getClientOrganizations?.map(
    (client) => ({
      value: client.id,
      label: client.name,
    })
  );
  const clientOrganizationOptionsSorted = sortBy(
    clientOrganizationOptions,
    (o) => o.label
  );

  const jobLocationOptions = data?.candidateWorkLocations?.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  const jobRoleOptions = data?.candidateRoles?.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const jobRoleOptionsSorted = sortBy(jobRoleOptions, (o) => o.label);

  const vesselOptions = data?.getVessels?.map((vessel) => ({
    value: vessel.id,
    label: vessel.name,
  }));
  const vesselOptionsSorted = sortBy(vesselOptions, (o) => o.label);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: getFormValuesFromMoblyzeJob({
      moblyzeJobInner: moblyzeJob,
      clientOrganizationOptions,
      vesselOptions,
    }),
  });

  useEffect(() => {
    if (!moblyzeJob) {
      return;
    }

    // only run if last moblyze job was null
    if (prevProps?.moblyzeJob != null) {
      console.log("skippin bitch");
      return;
    }

    console.log("not skippin bitch");

    reset(
      getFormValuesFromMoblyzeJob({
        moblyzeJobInner: moblyzeJob,
        clientOrganizationOptions,
        vesselOptions,
      })
    );
    // intentiaonlly leave out clientOrganizationOptions, vesselOptions as dependencies
    // they cause infinite loop set states
  }, [moblyzeJob]);

  const uploadJobCoverImage = async ({ imageFile, moblyzeJobId }) => {
    if (!imageFile) {
      return;
    }

    const r = await MoblyzeApolloClient.mutate({
      mutation: UPLOAD_JOB_COVER_IMAGE_MUTATION,
      variables: { moblyzeJobId, imageFile },
    });

    toast({
      title: "Job Cover Image uploaded",
      status: "success",
    });
  };

  const createJobAndUploadLogo = async ({ variables, imageFile }) => {
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: CREATE_JOB_MUTATION,
        variables,
      });

      const moblyzeJobId = r.data.createJob.id;

      await uploadJobCoverImage({ moblyzeJobId, imageFile });

      toast({
        title: "Job created!",
        status: "success",
      });

      // navigate to new client
      navigate(JOB_DETAILS_PATH.replace(":id", moblyzeJobId));
    } catch (e) {
      console.log("Create job error", e);

      alert("Error creating job " + e.message);
    }
  };

  const updateJobAndUploadLogo = async ({
    variables,
    imageFile,
    moblyzeJobId,
  }) => {
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPDATE_JOB_MUTATION,
        variables,
      });

      await uploadJobCoverImage({ moblyzeJobId, imageFile });

      toast({
        title: "Job updated!",
        status: "success",
      });

      // navigate to new client
      navigate(JOB_DETAILS_PATH.replace(":id", moblyzeJobId));
    } catch (e) {
      console.log("Update job error", e);

      toast({
        title: "Error updating job",
        subtitle: e.message,
        status: "error",
      });
    }
  };

  async function onSubmit(values) {
    const {
      bullhornId,
      payRateValue,
      agencyContactEmail,
      agencyContactName,
      agencyContactPhone,
      clientContactEmail,
      clientContactName,
      clientContactPhone,
      duration,
      hiredInstructions,
      jobCategory,
      jobCoverImage,
      jobLocationDescription,
      jobPositionTitle,
      keyResponsibilities,
      moreRequirements,
      numOpenings,
      siteAddress,
      startDate,
      // --- option transformations
      clientOrganizationSelectedOption,
      employmentTypeSelectedOption,
      jobLocationSelectedOptions,
      jobRolesSelectedOptions,
      vesselSelectedOption,
      payCurrencySelectedOption,
      payFrequencySelectedOption,
    } = values;

    const variables = {
      bullhornId,
      payRateValue: payRateValue === "" ? null : payRateValue,
      payFrequency: payFrequencySelectedOption?.value,
      payCurrency: payCurrencySelectedOption?.value,
      category: jobCategory,
      title: jobPositionTitle,
      employmentType: employmentTypeSelectedOption?.value,
      numOpenings: numOpenings,
      clientOrganizationId: clientOrganizationSelectedOption?.value,
      employerContactName: clientContactName,
      employerContactEmail: clientContactEmail,
      employerContactPhone: clientContactPhone,
      agencyContactName: agencyContactName,
      agencyContactEmail: agencyContactEmail,
      agencyContactPhone: agencyContactPhone,
      location: jobLocationDescription,
      duration: duration,
      startDate: startDate ? new Date(startDate).toISOString() : null,
      requirementsDescription: moreRequirements,
      responsibilitiesDescription: keyResponsibilities,
      vesselId: vesselSelectedOption?.value,
      hiredInstructions: hiredInstructions,
      siteAddress: siteAddress,
      candidateRoleIds: jobRolesSelectedOptions?.map((role) => role.value),
      candidateWorkLocationIds: jobLocationSelectedOptions?.map(
        (location) => location.value
      ),
    };

    if (!IsProduction()) {
      console.log(values);
      console.log(variables);
    }

    if (isCreate) {
      await createJobAndUploadLogo({ variables, imageFile: jobCoverImage });
    } else {
      variables.moblyzeJobId = moblyzeJob.id;
      await updateJobAndUploadLogo({
        variables,
        imageFile: jobCoverImage,
        moblyzeJobId: moblyzeJob.id,
      });
    }
  }

  const [newVesselId, setNewVesselId] = React.useState(null);

  const handleNewVessselIdSelectedFromModal = (vesselId) => {
    setNewVesselId(vesselId);

    setValue(
      "vesselSelectedOption",
      vesselOptions.find((option) => option.value === vesselId)
    );
  };

  React.useEffect(() => {
    if (newVesselId) {
      setValue(
        "vesselSelectedOption",
        vesselOptions.find((option) => option.value === newVesselId)
      );
    }
  }, [newVesselId, setValue, vesselOptions]);

  if (loading || error) {
    return null;
  }

  let title = "";
  if (isCuration) {
    title = "Curate Job";
  } else if (isCreate) {
    title = "Add New Job";
  } else {
    title = "Edit Job";
  }

  return (
    <Flex direction="column">
      <PageTitle title={title} />

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Client details */}
        <SectionTitle title="Client Details" />

        <SelectInput
          name="clientOrganizationSelectedOption"
          label="Client Company"
          placeholder="Select a client company"
          required
          options={clientOrganizationOptionsSorted}
          register={register}
          control={control}
          errors={errors}
          disabled={!isCreate}
        />
        <Spacer />
        <BasicInput
          name="clientContactName"
          label="Client - Primary Contact Name"
          placeholder="Firstname Lastname"
          required
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="clientContactEmail"
          label="Client - Contact Email"
          placeholder="name@example.com"
          required
          typeOverride="email"
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="clientContactPhone"
          label="Client - Contact Phone"
          placeholder="+1-555-555-5555"
          required
          typeOverride="tel"
          register={register}
          control={control}
          errors={errors}
        />
        <Spacer />
        <BasicInput
          name="agencyContactName"
          label="JAB - Primary Contact Name"
          placeholder="Firstname Lastname"
          required
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="agencyContactEmail"
          label="JAB - Contact Email"
          placeholder="example@jab-recruitment.com"
          required
          typeOverride="email"
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="agencyContactPhone"
          label="JAB - Contact Phone"
          placeholder="+1-555-555-5555"
          required
          typeOverride="tel"
          register={register}
          control={control}
          errors={errors}
        />
        <SectionTitle title="Job Details" />

        <BasicInput
          name="bullhornId"
          label="Bullhorn Job ID"
          register={register}
          control={control}
          errors={errors}
        />

        <BasicInput
          name="jobCategory"
          label="Job Title"
          required
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="jobPositionTitle"
          label="Position Title"
          required
          register={register}
          control={control}
          errors={errors}
        />
        <MultiSelectInput
          name="jobLocationSelectedOptions"
          label="Job Locations"
          required
          options={jobLocationOptions}
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="jobLocationDescription"
          label="Location Description"
          required
          placeholder="London, Epsom, Bristol & Edinburgh"
          register={register}
          control={control}
          errors={errors}
        />
        <SelectInput
          name="employmentTypeSelectedOption"
          label="Job Type"
          placeholder="Job Type"
          required
          options={employmentTypeOptions}
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="startDate"
          label="Start Date"
          placeholder="Start Date"
          required
          typeOverride="date"
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="duration"
          label="Duration"
          placeholder="12 Weeks"
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="numOpenings"
          label="# of Openings"
          required
          typeOverride="number"
          register={register}
          control={control}
          errors={errors}
        />
        <MultiSelectInput
          name="jobRolesSelectedOptions"
          label="Roles"
          required
          options={jobRoleOptionsSorted}
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="moreRequirements"
          label="More Requirements"
          required
          typeOverride="textarea"
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="keyResponsibilities"
          label="Key Responsibilities"
          required
          typeOverride="textarea"
          register={register}
          control={control}
          errors={errors}
        />
        <ImageUpload
          name="jobCoverImage"
          label="Job Cover Image"
          placeholder="Select a job cover image"
          required={isCreate}
          register={register}
          control={control}
          errors={errors}
        />
        <SectionTitle title="Post-Hire Details" />
        <BasicInput
          name="hiredInstructions"
          label="Hired Instructions"
          typeOverride="textarea"
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="siteAddress"
          label="Site Address"
          typeOverride="textarea"
          register={register}
          control={control}
          errors={errors}
        />

        <SectionTitle title="Job Pay Rate" />
        <SelectInput
          name="payCurrencySelectedOption"
          label="Currency"
          placeholder="Currency"
          options={payCurrencyOptions}
          register={register}
          control={control}
          errors={errors}
        />
        <BasicInput
          name="payRateValue"
          label="Value"
          typeOverride="number"
          register={register}
          control={control}
          errors={errors}
        />
        <SelectInput
          name="payFrequencySelectedOption"
          label="Frequency"
          placeholder="Frequency"
          options={payFrequencyOptions}
          register={register}
          control={control}
          errors={errors}
        />

        <SectionTitle title="Vessel Details" />
        <SelectInput
          name="vesselSelectedOption"
          label="Vessel"
          placeholder="Select a vessel"
          options={vesselOptionsSorted}
          register={register}
          control={control}
          errors={errors}
        />

        <AddVesselModal
          refetchParent={refetch}
          setNewVesselId={handleNewVessselIdSelectedFromModal}
        />

        <br />

        <Button mt={8} isLoading={isSubmitting} type="submit">
          {isCreate ? "Activate New Job" : "Update Job"}
        </Button>
      </form>
    </Flex>
  );
}

function CreateEditJobPage({ loading, data, error, refetch, currentUser }) {
  return (
    <Box
      mr={{
        base: "0px",
        md: "40%",
        lg: "50%",
      }}
    >
      <CreateEditJobForm
        loading={loading}
        data={data}
        error={error}
        refetch={refetch}
        currentUser={currentUser}
      />
    </Box>
  );
}

function PageComponent({ children }) {
  const { id } = useParams();

  // edit mode
  if (id) {
    return WithQuery(
      CreateEditJobPage,
      JOB_EDIT_PAGE_QUERY,
      { id },
      {
        children,
      }
    )();
  }

  // create mode
  return WithQuery(
    CreateEditJobPage,
    JOB_CREATE_PAGE_QUERY,
    {},
    {
      children,
    }
  )();
}

export default WithAppLayout(PageComponent);
