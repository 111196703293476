import React from "react";
import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";

import { UPLOAD_CANDIDATE_RESUME_MUTATION } from "../graphql/agencies";
import MoblyzeApolloClient from "../services/MoblyzeApolloClient";
import { ResumeFilePicker } from "./Filepicker";

export default function UploadCandidateResumeForm({ candidateUser, refetch }) {
  const toast = useToast();

  const [resumeFile, setResumeFile] = React.useState(candidateUser?.resumeFile);
  const [isLoading, setIsLoading] = React.useState(false);

  const onSaveButtonPress = async () => {
    if (!resumeFile) {
      return;
    }

    setIsLoading(true);

    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPLOAD_CANDIDATE_RESUME_MUTATION,
        variables: { candidateUserId: candidateUser.id, resumeFile },
      });
      console.log("Update user response", r);

      if (refetch) {
        refetch();
      }

      toast({
        title: "The profile has been updated",
        status: "success",
      });
    } catch (e) {
      console.log("Update user error", e);
      toast({
        title: e?.message || JSON.stringify(e),
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      borderRadius="5px"
      background="gray.800"
      p="44px 20px"
      width="100%"
      mt={6}
    >
      <Text
        color={"white"}
        fontSize={"28px"}
        fontWeight={600}
        lineHeight={"40px"}
      >
        Replace Candidate Resume
      </Text>
      <Stack spacing={4} mt="8" width="100%">
        <ResumeFilePicker name="resumeFile" setResumeValue={setResumeFile} />
        <Flex align="center">
          <Button
            width="100%"
            onClick={onSaveButtonPress}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Replace Candidate Resume
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
