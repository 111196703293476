import React from "react";
import { useNavigate } from "react-router-dom";

import WithAppLayout from "../../../components/WithAppLayout";
import PageTableWithSearch from "../../../components/PageTableWithSearch/PageTableWithSearch";

import { recruitersColumns } from "../../../components/PageTableWithSearch/tableDataHelper";
import WithQuery from "../../../components/WithQuery";
import { GET_RECRUITER_USERS_QUERY } from "../../../graphql/agencies";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

function AllRecruitersPageInner({
  data,
  error,
  loading,
  refetch,
  currentUser,
}) {
  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <PageTableWithSearch
      title="Recruiters"
      columns={recruitersColumns}
      data={data?.getRecruiterUsers || []}
    />
  );
}

function PageComponent({ children }) {
  return WithQuery(AllRecruitersPageInner, GET_RECRUITER_USERS_QUERY, null, {
    children,
  })();
}

export default WithAppLayout(PageComponent);
