import React, { Fragment, useCallback } from "react";
import { Flex, Icon, Image, Text, Box } from "@chakra-ui/react";

import _ from "lodash";

import { ReactComponent as Monetization } from "../../assets/icons/monetization.svg";
import { ReactComponent as MonetizationBook } from "../../assets/icons/monetization-book.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as Like } from "../../assets/icons/like.svg";
import { ReactComponent as Dislike } from "../../assets/icons/dislike.svg";
import {
  CandidateTriageStatusApprovedByClient,
  CandidateTriageStatusApprovedByRecruiter,
  CandidateTriageStatusPassedByClient,
} from "../../graphql/jobs";

import colors from "../../helpers/colors";

function CandidateListItem({
  candidateReportList,
  candidateTriageList,
  select,
  setSelect,
  isClient,
  markViewedByRecruiterType,
}) {
  const listLength =
    candidateReportList?.length || candidateTriageList?.length || 0;

  const borderRadius = useCallback(
    (index) => {
      if (listLength === 1) {
        return "5px 5px 5px 5px";
      } else if (index === 0) {
        return "5px 5px 0px 0px";
      } else if (index === listLength - 1) {
        return "0px 0px 5px 5px";
      } else {
        return "initial";
      }
    },
    [listLength]
  );

  const borderBottom = useCallback(
    (index) => {
      if (index < listLength - 1) {
        return "1px solid #414141";
      } else {
        return "initial";
      }
    },
    [listLength]
  );

  if (!candidateReportList && !candidateTriageList) {
    return null;
  }

  const renderClientShortlistStatus = ({
    candidate,
    candidateTriage,
    candidateReport,
  }) => {
    const rightArrow = (
      <Flex height="100%" alignItems="center">
        <Icon as={ArrowRight} />
      </Flex>
    );

    if (!isClient) {
      // render for recruiter

      if (candidate.id === select?.id) {
        return <Fragment>{rightArrow}</Fragment>;
      }

      if (!!candidateReport && markViewedByRecruiterType !== "") {
        // render for recruiter
        if (candidateReport?.viewedByRecruiter === false) {
          return (
            <div
              style={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                backgroundColor: colors.brandBlue,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          );
        }
      }

      return null;
    }

    return (
      <Fragment>
        {candidateTriage?.status === CandidateTriageStatusApprovedByClient && (
          <Flex height="100%" alignItems="center">
            <Icon fontSize="36px" marginRight="20px" as={Like} />
          </Flex>
        )}
        {candidateTriage?.status === CandidateTriageStatusPassedByClient && (
          <Flex height="100%" alignItems="center">
            <Icon fontSize="36px" marginRight="20px" as={Dislike} />
          </Flex>
        )}
        {candidateTriage?.status ===
          CandidateTriageStatusApprovedByRecruiter && (
          <Flex height="100%">
            <Text
              position="absolute"
              right="24px"
              fontSize="12px"
              fontWeight="400"
              fontStyle="italic"
              lineHeight="16px"
              color={colors.charcoalText}
              pr="5"
            >
              Review Candidate
            </Text>
          </Flex>
        )}
        {candidateTriage?.id === select?.id && rightArrow}
      </Fragment>
    );
  };

  const renderCandidateReportListItem = ({ candidateReport, index }) => {
    const candidate = candidateReport?.candidateUser;

    if (!candidate) {
      return null;
    }

    return (
      <Flex
        key={candidate.id}
        cursor="pointer"
        onClick={() => setSelect(candidate)}
        p="30px 14px"
        gap="14px"
        background={candidate.id === select?.id ? "gray.800" : "gray.900"}
        borderRadius={borderRadius(index)}
        borderBottom={borderBottom(index)}
        alignItems="center"
      >
        <Box
          style={{
            backgroundImage: `url(${
              candidate?.profileImageUrl ||
              "https://ui-avatars.com/api/?name=" +
                candidate?.fullName.replace(/ /g, "+") +
                "&background=random&rounded=true"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "60px",
            width: "60px",
            borderRadius: "60px",
          }}
          alt="User"
          mr="2"
          ml="2"
        />
        <Flex flex={1} direction="column" justifyContent={"center"}>
          <Text fontSize="lg" fontWeight="semibold">
            {candidate?.fullName}
          </Text>
        </Flex>
        {renderClientShortlistStatus({ candidate, candidateReport })}
      </Flex>
    );
  };

  const renderCandidateTriageListItem = ({
    candidate,
    candidateTriage,
    index,
  }) => {
    if (!candidateTriage) {
      return null;
    }

    return (
      <Flex
        key={candidateTriage.id}
        cursor="pointer"
        onClick={() => setSelect(_.cloneDeep(candidateTriage))}
        p="30px 14px"
        gap="14px"
        background={candidateTriage.id === select?.id ? "gray.800" : "gray.900"}
        borderRadius={borderRadius(index)}
        borderBottom={borderBottom(index)}
      >
        <Box
          style={{
            backgroundImage: `url(${
              candidate?.profileImageUrl ||
              "https://ui-avatars.com/api/?name=" +
                candidate?.fullName.replace(/ /g, "+") +
                "&background=random&rounded=true"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "60px",
            width: "60px",
            borderRadius: "60px",
          }}
          alt="User"
          mr="2"
          ml="2"
        />
        <Flex flex={1} direction="column" justifyContent={"center"}>
          <Text fontSize="lg" fontWeight="semibold">
            {candidate?.fullName}
          </Text>
        </Flex>
        {renderClientShortlistStatus({ candidate, candidateTriage })}
      </Flex>
    );
  };

  return (
    <Flex position="relative" w="435px" h="100%" direction="column">
      {candidateReportList?.map((candidateReport, i) =>
        renderCandidateReportListItem({ candidateReport, index: i })
      )}
      {candidateTriageList?.map((candidateTriage, i) =>
        renderCandidateTriageListItem({
          candidate: candidateTriage.candidateUser,
          candidateTriage,
          index: i,
        })
      )}
    </Flex>
  );
}

export default CandidateListItem;
