import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    auth {
      login(email: $email, password: $password)
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const COMPLETE_RESET_PASSWORD_MUTATION = gql`
  mutation CompleteResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    completeResetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    )
  }
`;

export const GET_PUBLIC_INVITE_INFO_QUERY = gql`
  query GetPublicInviteInfo($inviteToken: String!) {
    getPublicInviteInfo(inviteToken: $inviteToken) {
      email
      firstName
      lastName
    }
  }
`;

export const CLAIM_INVITE_MUTATION = gql`
  mutation ClaimInvite($password: String!, $inviteToken: String!) {
    auth {
      claimInvite(password: $password, inviteToken: $inviteToken)
    }
  }
`;
