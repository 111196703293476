import React from "react";
import { Flex, Link, Text } from "@chakra-ui/react";

import { TERMS_LINKS } from "../helpers/config";

const terms = TERMS_LINKS;

const renderTerm = ({ link, title }, textColor) => {
  return (
    <Link
      color={textColor}
      href={link}
      key={link}
      target="_blank"
      textDecoration={"underline"}
    >
      {title}
    </Link>
  );
};

export const UserTermsLink = ({ color }) => {
  return renderTerm(terms.user, color);
};

export const PrivacyPolicyLink = ({ color }) => {
  return renderTerm(terms.privacy, color);
};

export function CreateAccountTermsText({ color }) {
  const textColor = "white";

  return (
    <Flex direction="row" justify="center" align="center" mt="2" mb="2">
      <Text
        fontSize={"14px"}
        fontWeight="400"
        textAlign="left"
        color={color || textColor}
      >
        By creating an account, you accept the{" "}
        <UserTermsLink color={color || textColor} /> and{" "}
        <PrivacyPolicyLink color={color || textColor} />.
      </Text>
    </Flex>
  );
}

export function AccountTermsText({ color }) {
  const textColor = "white";

  return (
    <Flex direction="row" justify="center" align="center" mt="2" mb="2">
      <Text
        fontSize={"14px"}
        fontWeight="400"
        textAlign="center"
        color={color || textColor}
      >
        <UserTermsLink color={color || textColor} />
        {" | "}
        <PrivacyPolicyLink color={color || textColor} />
      </Text>
    </Flex>
  );
}
