import { Flex, Stack, Text } from "@chakra-ui/react";

import { MdAdd } from "react-icons/md";

import { NavButton } from "./NavButton";
import colors from "../helpers/colors";

import {
  UNCURATED_JOBS_PATH,
  ACTIVE_JOBS_PATH,
  ARCHIVED_JOBS_PATH,
  ADD_JOB_PATH,
  ALL_CLIENTS_PATH,
  ADD_CLIENT_PATH,
  ALL_RECRUITERS_PATH,
  IMPORT_JOBS_PATH,
  ALL_CANDIDATES_PATH,
  INVITE_CANDIDATE_PATH,
} from "../routes";
import { AgencyRecruiterShareNavButton } from "./Shortlist/AgencyShareActions";

export default function Sidebar({ currentUser }) {
  const renderStackLabel = (label) => {
    return (
      <Text fontSize="16px" color={colors.white} fontWeight="700" px="4">
        {label}
      </Text>
    );
  };

  const uncurateJobsCount =
    currentUser?.recruiterOrganizationReport?.uncuratedJobsCount || null;

  return (
    <Flex
      py="8"
      style={{
        borderRight: `1px solid  ${colors.greyBorderDark}`,
      }}
    >
      <Stack justify="space-between" spacing="0" width="700">
        <Stack spacing="8" shouldWrapChildren>
          <Stack>
            {renderStackLabel("Jobs")}
            <Stack spacing="0">
              <NavButton
                label="Uncurated"
                count={uncurateJobsCount}
                showDot={uncurateJobsCount && uncurateJobsCount > 0}
                path={UNCURATED_JOBS_PATH}
              />
              <NavButton
                label="Active"
                showDot={true}
                path={ACTIVE_JOBS_PATH}
              />
              <NavButton label="Archived" path={ARCHIVED_JOBS_PATH} />
              <NavButton label="Add New Job" icon={MdAdd} path={ADD_JOB_PATH} />
              <NavButton
                label="Import Jobs"
                icon={MdAdd}
                path={IMPORT_JOBS_PATH}
              />
            </Stack>
          </Stack>
          <Stack>
            {renderStackLabel("Clients")}
            <Stack spacing="0">
              <NavButton label="All" path={ALL_CLIENTS_PATH} />
              <NavButton
                label="Add New Client"
                icon={MdAdd}
                path={ADD_CLIENT_PATH}
              />
            </Stack>
          </Stack>
          <Stack>
            {renderStackLabel("Recruiters")}
            <Stack spacing="0">
              <NavButton label="All" path={ALL_RECRUITERS_PATH} />
              <AgencyRecruiterShareNavButton />
            </Stack>
          </Stack>
          <Stack>
            {renderStackLabel("Workers")}
            <Stack spacing="0">
              <NavButton label="All" path={ALL_CANDIDATES_PATH} />
              <NavButton
                label="Invite"
                icon={MdAdd}
                path={INVITE_CANDIDATE_PATH}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
}
