import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { GetLocalAuthRole } from "../services/MoblyzeAuth";

export default function AgencyRoute(props) {
  const [isRecruiterRole, setIsRecruiterRole] = useState(null);

  // Check auth state on component mount
  useEffect(() => {
    const fetchData = async () => {
      const role = await GetLocalAuthRole();

      setIsRecruiterRole(role === "Recruiter");
    };
    fetchData();
  });

  if (isRecruiterRole == null) {
    return null;
  } else if (isRecruiterRole) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    );
  }
}
