import React, { useState } from "react";
import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Text,
  Button,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Stack,
} from "@chakra-ui/react";
import _ from "lodash";

import ShortlistEmptyState from "./ShortlistEmptyState";
import CandidateListItem from "./CandidateListItem";
import CandidateDetails from "./CandidateDetails";
import ProgressionTabs from "./ProgressionTabs";
import AgencyAddMoreCandidatesComponent from "./AgencyAddMoreCandidates";
import { AgencyClientShareAction } from "./AgencyShareActions";
import { usePrevious } from "../../helpers/hooks";

const candidatesTab = "Candidates";
const approvedTab = "Approved";
const hiredTab = "Hired";
const tabItems = [
  { name: candidatesTab, displayText: candidatesTab },
  { name: approvedTab, displayText: approvedTab },
  { name: hiredTab, displayText: hiredTab },
];

const candidatesSubTabInterested = "Interested";
const candidatesSubTabShortlisted = "Shortlist";
const subTabNames = [candidatesSubTabInterested, candidatesSubTabShortlisted];

function AgencyShortlistBody({
  moblyzeJob,
  isArchived,
  tabName,
  agencyCandidateTriageInterestedCandidates,
  agencyCandidateTriageShortlistedCandidates,
  agencyCandidateTriageApprovedCandidates,
  agencyCandidateTriageHiredCandidates,
  refetch,
}) {
  let displayList = React.useMemo(() => [], []);

  const [selectedSubTabItem, setSelectedSubTabItem] = useState(
    candidatesSubTabInterested
  );

  let emptyMessage = "";
  let numInterested = agencyCandidateTriageInterestedCandidates?.length || 0;
  let numShortlisted = agencyCandidateTriageShortlistedCandidates?.length || 0;

  if (tabName === candidatesTab) {
    if (selectedSubTabItem === candidatesSubTabInterested) {
      displayList = agencyCandidateTriageInterestedCandidates;

      emptyMessage = `No candidates have indicated interest yet.`;
    } else if (selectedSubTabItem === candidatesSubTabShortlisted) {
      displayList = agencyCandidateTriageShortlistedCandidates;

      emptyMessage = `You haven't added any candidates onto the shortlist.

Add candidates and accept interested candidates to build your shortlist.`;
    }
  } else if (tabName === approvedTab) {
    displayList = agencyCandidateTriageApprovedCandidates;

    emptyMessage = `The client hasn't approved any candidates yet.`;
  } else if (tabName === hiredTab) {
    displayList = agencyCandidateTriageHiredCandidates;

    emptyMessage = `No candidates have been hired yet.`;
  }

  const [candidate, setCandidate] = useState(
    displayList?.length > 0 ? displayList[0]?.candidateUser : null
  );

  const prevProps = usePrevious({ tabName, selectedSubTabItem });

  const renderSubTabs = () => {
    if (tabName === candidatesTab) {
      return (
        <Tabs size="md" mb="5" colorScheme="white" variant="unstyled">
          <TabList>
            {subTabNames.map((subTabName) => (
              <Tab
                key={subTabName}
                onClick={() => setSelectedSubTabItem(subTabName)}
              >
                {subTabName} (
                {subTabName === candidatesSubTabInterested
                  ? numInterested
                  : numShortlisted}
                )
              </Tab>
            ))}
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="white"
            borderRadius="1px"
          />
          <TabIndicator />
        </Tabs>
      );
    }

    return null;
  };

  // if tabName changes, update candidate
  React.useEffect(() => {
    // only if tabName changes

    if (
      prevProps?.tabName !== tabName ||
      prevProps?.selectedSubTabItem !== selectedSubTabItem
    ) {
      setCandidate(
        displayList?.length > 0 ? displayList[0]?.candidateUser : null
      );
    }
  }, [tabName, displayList, selectedSubTabItem, prevProps]);

  if (displayList.length === 0) {
    return (
      <Stack spacing="3">
        {renderSubTabs()}
        <Flex gap="50px" alignItems={"center"} justifyContent={"center"}>
          <ShortlistEmptyState message={emptyMessage} />
        </Flex>
      </Stack>
    );
  }

  const candidateReport = displayList.find(
    (candidateReport) => candidateReport?.candidateUser?.id === candidate?.id
  );

  let markViewedByRecruiterType = "";

  if (
    tabName === candidatesTab &&
    selectedSubTabItem === candidatesSubTabInterested
  ) {
    markViewedByRecruiterType = "Interested";
  } else if (tabName === approvedTab) {
    markViewedByRecruiterType = "ApprovedByClient";
  }

  return (
    <Stack spacing="3">
      {renderSubTabs()}

      <Flex gap="50px" alignItems={"center"} justifyContent={"center"}>
        <CandidateListItem
          setSelect={setCandidate}
          select={candidate}
          candidateReportList={displayList}
          markViewedByRecruiterType={markViewedByRecruiterType}
        />
        {candidate && (
          <CandidateDetails
            markViewedByRecruiterType={markViewedByRecruiterType}
            refetch={refetch}
            moblyzeJob={moblyzeJob}
            candidateReport={candidateReport}
            showAgencyPassAddActions={
              !isArchived &&
              tabName === candidatesTab &&
              selectedSubTabItem === candidatesSubTabInterested
            }
            showAgencyRemoveShortlistAction={
              !isArchived &&
              tabName === candidatesTab &&
              selectedSubTabItem === candidatesSubTabShortlisted
            }
            showAgencyMarkHiredAction={!isArchived && tabName === approvedTab}
            showClientNotes={tabName === approvedTab}
          />
        )}
      </Flex>
    </Stack>
  );
}

export default function AgencyShortlist({
  moblyzeJob,
  isArchived,
  agencyCandidateTriageInterestedCandidates,
  agencyCandidateTriageShortlistedCandidates,
  agencyCandidateTriageApprovedCandidates,
  agencyCandidateTriageHiredCandidates,
  refetch,
}) {
  const [selectedTabItemName, setSelectedTabItemName] = useState(
    tabItems[0].name
  );

  const [generatedTabs, setGeneratedTabs] = useState(tabItems);

  const [showAddMoreCandidates, setShowAddMoreCandidates] = useState(false);

  React.useEffect(() => {
    const displayTabNames = _.cloneDeep(tabItems);

    if (agencyCandidateTriageInterestedCandidates?.length > 0) {
      displayTabNames[0].displayText = `${candidatesTab} (${agencyCandidateTriageInterestedCandidates.length})`;
    }

    if (agencyCandidateTriageApprovedCandidates?.length > 0) {
      displayTabNames[1].displayText = `${approvedTab} (${agencyCandidateTriageApprovedCandidates.length})`;
    }

    if (agencyCandidateTriageHiredCandidates?.length > 0) {
      displayTabNames[2].displayText = `${hiredTab} (${agencyCandidateTriageHiredCandidates.length})`;
    }

    setGeneratedTabs(displayTabNames);
  }, [
    agencyCandidateTriageInterestedCandidates,
    agencyCandidateTriageApprovedCandidates,
    agencyCandidateTriageHiredCandidates,
  ]);

  if (!moblyzeJob) {
    return null;
  }

  if (showAddMoreCandidates) {
    return (
      <AgencyAddMoreCandidatesComponent
        moblyzeJob={moblyzeJob}
        onBackButtonClicked={() => {
          setShowAddMoreCandidates(false);
          refetch();
        }}
      />
    );
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <ProgressionTabs
          tabs={generatedTabs}
          selectedTabItemName={selectedTabItemName}
          setSelectedTabItemName={setSelectedTabItemName}
          refetch={refetch}
        />
        {!isArchived && selectedTabItemName === candidatesTab && (
          <Flex alignItems="center" direction="row" gap="16px">
            <Button
              colorScheme="white"
              variant="outline"
              onClick={() => setShowAddMoreCandidates(true)}
            >
              Add Candidates to Shortlist
            </Button>
            <AgencyClientShareAction moblyzeJob={moblyzeJob} />
          </Flex>
        )}
      </Flex>

      <AgencyShortlistBody
        moblyzeJob={moblyzeJob}
        tabName={selectedTabItemName}
        agencyCandidateTriageInterestedCandidates={
          agencyCandidateTriageInterestedCandidates
        }
        agencyCandidateTriageShortlistedCandidates={
          agencyCandidateTriageShortlistedCandidates
        }
        agencyCandidateTriageApprovedCandidates={
          agencyCandidateTriageApprovedCandidates
        }
        agencyCandidateTriageHiredCandidates={
          agencyCandidateTriageHiredCandidates
        }
        isArchived={isArchived}
        refetch={refetch}
      />
    </>
  );
}
