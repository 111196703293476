import { Text } from "@chakra-ui/react";

export default function PageTitle({ title, ...otherProps }) {
  return (
    <Text
      color={"white"}
      fontSize={"36px"}
      fontWeight={600}
      lineHeight={"40px"}
      {...otherProps}
    >
      {title}
    </Text>
  );
}
