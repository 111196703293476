import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

export const LoadingIndicator = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Spinner />
    </Box>
  );
};
