export const LOCAL_STORAGE_KEYS = {
  CURRENT_USER_KEY: "currentUser",
  ANALYTICS: "analytics",
}

class MoblyzeLocalStorage {
  static async save({ key, data }) {
    localStorage.setItem(key, JSON.stringify(data));
    return data;
  }

  static async load({ key }) {
    return JSON.parse(localStorage.getItem(key));
  }

  static async remove({ key }) {
    localStorage.removeItem(key);
  }
}

export default MoblyzeLocalStorage;
