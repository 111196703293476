import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import moment from "moment";

import { ReactComponent as Pin } from "../../assets/icons/pin.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as People } from "../../assets/icons/people.svg";

import { Icon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { CLIENT_SHORTLIST_PATH } from "../../routes";
import JobDetails from "../JobDetails";
import { GetLocalAuthRole } from "../../services/MoblyzeAuth";

function JobListItem({ moblyzeJob }) {

  const [showJobDetail, setShowJobDetail] = useState();

  const [role, setRole] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const authRole = await GetLocalAuthRole();
      setRole(authRole);
    };
    fetchData();
  }, []);

  if (!moblyzeJob) {
    return null;
  }

  return (
    <Flex gap="16px" p="26px" background="#272727" w="100%">
      <Image h={168} w={180} borderRadius={6} src={moblyzeJob.coverImageUrl} />
      <Flex
        flex={1}
        gap="20px"
        direction="column"
        justifyContent="space-between"
      >
        <Box>
          <Text fontSize="lg" fontWeight="semibold" lineHeight="7">
            {moblyzeJob.category}
          </Text>
          <Text fontSize="md" fontWeight="normal" lineHeight="6">
            {moblyzeJob.title}
          </Text>
        </Box>

        <Flex gap="16px" direction="column">
          {!!moblyzeJob.location && (
            <Flex gap="8px">
              <Icon fontSize={20} as={Pin} />
              <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                {moblyzeJob.location}
              </Text>
            </Flex>
          )}
          {!!moblyzeJob?.startDate && (
            <Flex gap="8px">
              <Icon fontSize={20} as={Calendar} />
              <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                Starting {moment(moblyzeJob?.startDate).format("MMMM Do, YYYY")}
              </Text>
            </Flex>
          )}
          {!!moblyzeJob.numOpenings && (
            <Flex gap="8px">
              <Icon fontSize={20} as={People} />
              <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                {moblyzeJob.numOpenings} Openings
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex direction="column">
        <Link to={CLIENT_SHORTLIST_PATH.replace(":id", moblyzeJob.id)}>
          <Button
            marginBottom={18}
            color="whiteAlpha.700"
            _hover={{ bg: "whiteAlpha.50" }}
            variant="outline"
          >
            View Shortlist
          </Button>
        </Link>
        <Button color="gray.500" variant="link" onClick={() => setShowJobDetail(true)}>
          Job Details
        </Button>
      </Flex>
      <JobDetails
        isOpen={showJobDetail}
        onClose={() => setShowJobDetail(false)}
        moblyzeJob={moblyzeJob}
        isClient={role === "Client"}
      />
    </Flex>
  );
}

export default JobListItem;
