import React, { useEffect } from "react";
import { Box, Flex, Text, Icon, Tag, TagLabel } from "@chakra-ui/react";
import moment from "moment";

import { FiDownload, FiExternalLink } from "react-icons/fi";

import {
  AgencyPassAddActions,
  AgencyRemoveShortlistAction,
  AgencyMarkHiredAction,
  AgencyAddToShortlistAction,
} from "./AgencyShortlistActions";

import { ClientPassAddActions } from "./ClientShortlistActions";

import { CLIENT_NOTES_FOR_CANDIDATE_TRIAGE_QUERY } from "../../graphql/jobs";
import WithQuery from "../WithQuery";
import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";
import { RECRUITER_VIEWED_CANDIDATE_FOR_JOB_MUTATION } from "../../graphql/agencies";
import { LoadingIndicator } from "../LoadingIndicator";
import colors from "../../helpers/colors";

function ClientNotesInner({
  candidate,
  moblyzeJob,
  data,
  loading,
  error,
  refetch,
}) {
  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return null;
  }

  const clientNotes = data.clientNotesForCandidateTriage;

  if (!clientNotes || clientNotes.length === 0) {
    return null;
  }

  return (
    <Box
      borderRadius="5px"
      flex={1}
      background="gray.800"
      p="44px 20px"
      width="100%"
    >
      <Text fontSize="md" fontWeight="semibold" lineHeight="6" mb="3">
        Notes from Client:
      </Text>
      <Text fontSize="md" fontWeight="normal" lineHeight="6">
        {clientNotes}
      </Text>
    </Box>
  );
}

function ClientNotes({ moblyzeJob, candidate }) {
  if (!candidate || !moblyzeJob) {
    return null;
  }

  return WithQuery(
    ClientNotesInner,
    CLIENT_NOTES_FOR_CANDIDATE_TRIAGE_QUERY,
    { moblyzeJobId: moblyzeJob.id, candidateUserId: candidate.id },
    {
      moblyzeJob,
      candidate,
    }
  )();
}

function CandidateDetails({
  candidateReport,
  candidateTriage,
  moblyzeJob,
  isClient,
  showAgencyPassAddActions,
  showAgencyRemoveShortlistAction,
  showAgencyMarkHiredAction,
  showAgencyAddToShortlistAction,
  showClientPassAddActions,
  showClientNotes,
  refetch,
  markViewedByRecruiterType,
}) {
  // const employmentPreference = "Full Time";
  // const dateAvailable = "01/01/2024";
  // const currentJobTitle = "ROV Pilot";
  // const visaType = "Crewmember D, transit C-1";
  // const currentJobStatus = "Full Time";

  const employmentPreference = null;
  const dateAvailable = null;
  const currentJobTitle = null;
  const visaType = null;
  const currentJobStatus = null;

  // if recruiter views, call mutation on mount to mark as viewed
  useEffect(() => {
    if (
      !isClient &&
      candidateReport?.viewedByRecruiter === false &&
      markViewedByRecruiterType !== ""
    ) {
      MoblyzeApolloClient.mutate({
        mutation: RECRUITER_VIEWED_CANDIDATE_FOR_JOB_MUTATION,
        variables: {
          candidateUserId: candidateReport.candidateUser.id,
          moblyzeJobId: moblyzeJob.id,
          recruiterViewType: markViewedByRecruiterType,
        },
      }).then(() => {
        if (refetch) {
          refetch();
        }
      });
    }
  }, [candidateReport, markViewedByRecruiterType, isClient, moblyzeJob]);

  const renderActions = ({ candidate, candidateTriage, moblyzeJob }) => {
    if (isClient) {
      if (showClientPassAddActions) {
        return (
          <ClientPassAddActions
            candidateTriageStatus={candidateTriage?.status}
            candidate={candidate}
            moblyzeJob={moblyzeJob}
            refetch={refetch}
          />
        );
      }
      return null;
    }

    if (showAgencyPassAddActions) {
      return (
        <AgencyPassAddActions
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    if (showAgencyRemoveShortlistAction) {
      return (
        <AgencyRemoveShortlistAction
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    if (showAgencyMarkHiredAction) {
      return (
        <AgencyMarkHiredAction
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    if (showAgencyAddToShortlistAction) {
      return (
        <AgencyAddToShortlistAction
          candidate={candidate}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      );
    }

    return null;
  };

  const candidate =
    candidateReport?.candidateUser || candidateTriage?.candidateUser;

  return (
    <Flex direction="column" flex={1} gap="24px">
      {showClientNotes && (
        <ClientNotes candidate={candidate} moblyzeJob={moblyzeJob} />
      )}

      <Box
        borderRadius="5px"
        flex={1}
        background="gray.800"
        p="44px 20px"
        width="100%"
      >
        <Flex
          borderBottom="1px solid rgba(255, 255, 255, 0.24)"
          gap="24px"
          paddingBottom="34px"
        >
          <Box
            style={{
              backgroundImage: `url(${
                candidate?.profileImageUrl ||
                "https://ui-avatars.com/api/?name=" +
                  candidate?.fullName.replace(/ /g, "+") +
                  "&background=random&rounded=true"
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "60px",
              width: "60px",
              borderRadius: "60px",
            }}
            alt="User"
          />
          <Flex flex={1} direction="column" gap="4px" justifyContent={"center"}>
            <Text fontSize="2xl" fontWeight="semibold">
              {candidate?.fullName}
            </Text>
            <Flex gap="20px">
              {candidate?.candidateProfile?.agencySlugId && (
                <Flex alignItems="center" gap="4px">
                  <img
                    src={candidate?.candidateProfile?.agencyLogoUrl}
                    height={20}
                    width={20}
                    alt={candidate?.candidateProfile?.agencySlugId}
                    style={{ paddingRight: "4px" }}
                  />
                  <Text
                    fontSize="md"
                    fontWeight="normal"
                    lineHeight="6"
                    color={colors.greyLight}
                  >
                    {candidate?.candidateProfile?.agencySlugId}
                  </Text>
                </Flex>
              )}
              {/* <Flex alignItems="center" gap="4px">
              <Icon color="gray.700" fontSize="24px" as={MonetizationBook} />
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {data.location}
              </Text>
            </Flex> */}
            </Flex>
          </Flex>
          {renderActions({ candidate, candidateTriage, moblyzeJob })}
        </Flex>
        <Flex paddingTop="24px" gap="24px">
          {
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Actively Looking For Jobs?
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {candidate?.activelyLooking ||
                candidate?.candidateProfile?.activelyLooking
                  ? "Yes"
                  : "No"}
              </Text>
            </Flex>
          }
          {dateAvailable && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Date Available
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {moment(dateAvailable).format("DD/MM/YY")}
              </Text>
            </Flex>
          )}
          {employmentPreference && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Employee Preference
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {employmentPreference}
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex paddingTop="24px" gap="24px">
          {!!currentJobTitle && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Current Job Title
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {currentJobTitle}
              </Text>
            </Flex>
          )}
          {!!visaType && (
            <Flex direction="column" flex={1} gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Visa Type
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {visaType}
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex paddingTop="24px" gap="24px">
          {currentJobStatus && (
            <Flex direction="column" gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                Current Job Status
              </Text>
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                {currentJobStatus}
              </Text>
            </Flex>
          )}
          {candidate?.candidateProfile?.linkedInURL && (
            <Flex direction="column" gap="12px">
              <Text fontSize="md" fontWeight="semibold" lineHeight="6">
                LinkedIn Profile
              </Text>
              <a
                style={{ textDecoration: "underline" }}
                href={candidate?.candidateProfile?.linkedInURL}
                target="_blank"
                rel="noreferrer"
              >
                <Text fontSize="md" fontWeight="normal" lineHeight="6">
                  {candidate?.candidateProfile?.linkedInURL}
                  <Icon
                    color="white"
                    fontSize="16px"
                    as={FiExternalLink}
                    ml="2"
                  />
                </Text>
              </a>
            </Flex>
          )}
        </Flex>
        <Flex paddingTop="24px" gap="24px">
          <Flex direction="column" gap="12px">
            <Text fontSize="md" fontWeight="semibold" lineHeight="6">
              Roles
            </Text>
            <Flex wrap="wrap" gap="12px">
              {candidate?.candidateProfile?.roles?.map((role) => (
                <Tag
                  borderRadius="20px"
                  key={role.id}
                  size="lg"
                  variant="outline"
                  borderColor="#B7CBEA"
                  color="#B7CBEA"
                >
                  <TagLabel>{role.name}</TagLabel>
                </Tag>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {candidate?.candidateProfile?.resumeUrl && (
        <Box
          borderRadius="5px"
          flex={1}
          background="gray.800"
          p="20px"
          width="100%"
        >
          <Flex
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb="4"
          >
            <Text fontSize="md" fontWeight="semibold" lineHeight="6">
              CV/Resume
            </Text>

            {/* download link */}
            <a
              href={candidate?.candidateProfile?.resumeUrl}
              download
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                borderRadius="20px"
                p="4px 8px"
                fontSize="sm"
                fontWeight="semibold"
                lineHeight="6"
                color="white"
                cursor="pointer"
              >
                <Icon color="white" fontSize="16px" as={FiDownload} mr="2" />
                Download
              </Flex>
            </a>
          </Flex>

          <iframe
            title="resume"
            width="100%"
            height="800px"
            src={candidate?.candidateProfile?.resumePreviewUrl}
          ></iframe>
        </Box>
      )}
    </Flex>
  );
}

export default CandidateDetails;
