import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { MdAdd } from "react-icons/md";
import { NavButton } from "../NavButton";

import {
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Flex,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";

import { Icon } from "@chakra-ui/icons";
import colors from "../../helpers/colors";
import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";
import FormModal from "../FormModal";

import { INVITE_CLIENT_CONTACT_URL_MUTATION } from "../../graphql/clients";
import { INVITE_RECRUITER_URL_MUTATION } from "../../graphql/agencies";

function InvitationModal({
  isOpen,
  onOpen,
  onClose,
  moblyzeJob,
  isClientInvite,
  isRecruiterInvite,
}) {
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [inviteLink, setInviteLink] = useState("");

  const resetForm = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setInviteLink("");
  };

  // Reset state when modal is closed
  React.useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  if (!isClientInvite && !isRecruiterInvite) {
    return null;
  }

  const onSubmit = () => {
    if (!email || !firstName || !lastName) {
      toast({
        title: "Please fill out all fields",
        description: "Please fill out all fields to continue.",
        status: "error",
        duration: 5000,
      });
      return;
    }

    if (isClientInvite) {
      MoblyzeApolloClient.mutate({
        mutation: INVITE_CLIENT_CONTACT_URL_MUTATION,
        variables: {
          clientOrganizationId: moblyzeJob.clientOrganizationId,
          firstName,
          lastName,
          email,
        },
      })
        .then((res) => {
          toast({
            title: "Invite link created!",
            description: `An email with an invitation was sent, but you can also send this link to ${firstName} ${lastName}.`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          setInviteLink(res.data.inviteClientContactUrl);
        })
        .catch((err) => {
          Sentry.captureException(err);
          toast({
            title: "Error creating invite link",
            description: err?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } else if (isRecruiterInvite) {
      MoblyzeApolloClient.mutate({
        mutation: INVITE_RECRUITER_URL_MUTATION,
        variables: {
          firstName,
          lastName,
          email,
        },
      })
        .then((res) => {
          toast({
            title: "Invite link created!",
            description: `An email with an invitation was sent, but you can also send this link to ${firstName} ${lastName}.`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          setInviteLink(res.data.inviteRecruiterContactUrl);
        })
        .catch((err) => {
          Sentry.captureException(err);
          toast({
            title: "Error creating invite link",
            description: err?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  if (!!inviteLink && inviteLink.length > 0) {
    return (
      <FormModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={resetForm}
        submitTitle="Create another invite"
        title={`Invite link for ${email} created!`}
      >
        <Flex direction="column" align="center" mb="10">
          <Text mb="4" color={colors.charcoal} textAlign={"center"}>
            An email with an invitation was sent, but you can also send this
            link to {firstName} {lastName}:
          </Text>
          {/* make button to copy link */}
          <Textarea
            value={inviteLink}
            variant="light"
            isReadOnly
            mb="6"
            resize="none"
            width="100%"
            height="auto"
            overflow="hidden"
            whiteSpace="pre-wrap"
            wordBreak="break-word"
            borderRadius="sm"
            color={colors.charcoalText}
            _placeholder={{ color: colors.charcoalText }}
          />

          <Button
            variant="outline"
            colorScheme="green"
            onClick={() => {
              navigator.clipboard.writeText(inviteLink);
              toast({
                title: "Copied to clipboard!",
                description:
                  "The invite link has been copied to your clipboard.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            }}
          >
            Copy Invite Link
          </Button>
        </Flex>
      </FormModal>
    );
  }

  let submitTitle = "";
  let title = "";
  if (isClientInvite) {
    submitTitle = "Create Client Invitation";
    title = "Share shortlist with new client contact";
  } else if (isRecruiterInvite) {
    submitTitle = "Create Recruiter Invitation";
    title = "Invite a new Recruiter";
  }

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      submitTitle={submitTitle}
      title={title}
    >
      {isClientInvite && (
        <Text size="xxs" color={colors.charcoal} mb="8" textAlign={"center"}>
          If you previously shared with a client, they will automatically have
          the updated shortlist available to them and you don't have to re-
          share.
        </Text>
      )}

      <FormControl>
        <FormLabel htmlFor="firstName" fontSize="xs">
          First Name
        </FormLabel>

        <Input
          id="firstName"
          placeholder="First Name"
          type="text"
          variant="light"
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          value={firstName}
          mb="4"
        />

        <FormLabel htmlFor="lastName" fontSize="xs">
          Last Name
        </FormLabel>

        <Input
          id="lastName"
          placeholder="Last Name"
          type="text"
          variant="light"
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          value={lastName}
          mb="4"
        />

        <FormLabel htmlFor="email" fontSize="xs">
          Email
        </FormLabel>
        <Input
          id="email"
          placeholder="Email"
          type="email"
          variant="light"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          mb="4"
        />
      </FormControl>
    </FormModal>
  );
}

export function AgencyClientShareAction({ moblyzeJob }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <InvitationModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        moblyzeJob={moblyzeJob}
        isClientInvite
      />

      <Button onClick={onOpen}>Share Shortlist with Client</Button>
    </>
  );
}

export function AgencyRecruiterShareNavButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <InvitationModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isRecruiterInvite
      />

      <NavButton label="Invite" icon={MdAdd} onClick={onOpen} />
    </>
  );
}
