export const APP_NAME = "moblyze-web";

export const APP_VERSION = process.env.REACT_APP_GIT_SHA || "";

export const INTERCOM_APP_ID_JAB = "d5cnpbvv";
export const INTERCOM_APP_ID_MOBLYZE = "ejlwsqdn";

export const SENTRY_DSN_URL =
  "https://55df53da8bed430f6b7f95efe80c4043@o4504206668529664.ingest.sentry.io/4505990530400256";

export const IsProduction = () =>
  process.env.REACT_APP_VERCEL_ENV === "production" || false;

export const GetSentryEnvironment = () => {
  if (IsProduction()) {
    return "production";
  }
  return "development";
};

export const GetSentryReplaySessionSampleRate = () => {
  if (IsProduction()) {
    return 1.0;
  }
  return 0.1;
};

export const GetSentryDebug = () => {
  if (IsProduction()) {
    return false;
  }
  return true;
};

export const GetSentryEnabled = () => {
  return GetSentryEnvironment() !== "development";
};

export const ProductionAppUrl = "https://app.moblyze.me";
export const DevelopmentAppUrl = "http://localhost:3005";

export const GetActiveAppUrl = () => {
  if (IsProduction()) {
    return ProductionAppUrl;
  }
  return DevelopmentAppUrl;
};

export const ProductionApiUrl = "https://api.moblyze.me/query";
export const DevelopmentApiUrl = "http://localhost:3012/query";

export const GetActiveAPIUrl = () => {
  if (IsProduction()) {
    return ProductionApiUrl;
  }
  return DevelopmentApiUrl;
};

export const ProductLandingUrl = "https://moblyze.me";

export const SUPPORT_EMAIL = "support@moblyze.me";

export const PRIVACY_URL =
  "https://app.termly.io/document/privacy-policy/6d9b8d93-51ff-4a85-8d50-3e1c1d63d6bf";
export const USER_TERMS_URL =
  "https://app.termly.io/document/terms-of-service/5c408160-8121-4b87-966d-444a49833d45";

export const TERMS_LINKS = {
  user: { link: USER_TERMS_URL, title: "User Terms" },
  privacy: { link: PRIVACY_URL, title: "Privacy Policy" },
};
