import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

function Carousel({ data }) {
  return (
    <Box w={460}>
      <ReactCarousel
        infiniteLoop
        autoPlay
        showArrows={false}
        showStatus={false}
        transitionTime={3000}
        swipeable={true}
      >
        {data.map((value) => (
          <Box>
            <Box
              position="relative"
              _after={{
                content: `""`,
                position: "absolute",
                bottom: "0px",
                height: "30px",
                left: "0",
                right: "0",
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent)",
              }}
            >
              <Image borderRadius="5px 5px 0px 0px" src={value.imgPath} />
            </Box>
            <Box
              borderRadius="0px 0px 5px 5px"
              p="10px 38px"
              minH={250}
              background="black"
            >
              <Text
                marginBottom="10px"
                fontSize="2xl"
                lineHeight="8"
                fontWeight="bold"
                textAlign="center"
                padding="26px 0"
              >
                {value.title}
              </Text>
              <Text
                marginBottom="10px"
                fontSize="md"
                lineHeight="6"
                fontWeight="normal"
                textAlign="center"
              >
                {value.description}
              </Text>
            </Box>
          </Box>
        ))}
      </ReactCarousel>
    </Box>
  );
}

export default Carousel;
