import React from "react";
import {
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Icon,
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { Controller, useController, useForm } from "react-hook-form";
import { useRef } from "react";

export const HookFilePicker = ({
  inputName,
  placeholder,
  label,
  acceptedFileTypes,
  required,
  variant,
  control,
  errors,
}) => {
  const componentRef = useRef(null);

  const [fileValue, setFileValue] = React.useState(null);

  const otherProps = {};

  if (variant) {
    otherProps.variant = variant;
  }

  return (
    <Controller
      control={control}
      name={inputName}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <FormControl
          isInvalid={errors[inputName]}
          isRequired={required}
          mb="5"
          ml="3"
        >
          <FormLabel fontSize="md" color="white" htmlFor={name}>
            {label}
          </FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiFile} />
            </InputLeftElement>
            <input
              type="file"
              accept={acceptedFileTypes}
              name={name}
              style={{ opacity: "0", height: 1, width: 1 }}
              onChange={(e) => {
                if (e.target?.files?.length > 0) {
                  onChange(e.target.files[0]);

                  setFileValue(e.target.files[0]);
                }
              }}
              onBlur={onBlur}
              ref={(e) => {
                ref(e);
                componentRef.current = e; // you can still assign to ref
              }}
            />
            <Input
              placeholder={placeholder || "Your file ..."}
              onClick={() => componentRef.current.click()}
              onChange={() => {}}
              readOnly={true}
              value={(fileValue && fileValue?.name) || ""}
              variant={variant}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors[inputName] && "This field is required"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

const FileUpload = ({
  name,
  placeholder,
  acceptedFileTypes,
  control,
  children,
  isRequired = false,
  setParentValue,
}) => {
  const inputRef = useRef();
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    rules: { required: isRequired },
  });

  const onValueChange = (e) => {
    if (e.target?.files?.length > 0) {
      onChange(e.target.files[0]);
      if (setParentValue) {
        setParentValue(e.target.files[0]);
      }
    }
  };

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel htmlFor="writeUpFile">{children}</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FiFile} />
        </InputLeftElement>
        <input
          type="file"
          onChange={onValueChange}
          accept={acceptedFileTypes}
          name={name}
          ref={inputRef}
          {...inputProps}
          style={{ display: "none" }}
        />
        <Input
          placeholder={placeholder || "Your file ..."}
          onClick={() => inputRef.current.click()}
          readOnly={true}
          value={(value && value.name) || ""}
        />
      </InputGroup>
      <FormErrorMessage>{invalid}</FormErrorMessage>
    </FormControl>
  );
};

FileUpload.defaultProps = {
  acceptedFileTypes: "",
  allowMultipleFiles: false,
};

export function ImageFilePicker({ name, setImageValue }) {
  const { control } = useForm();

  return (
    <FileUpload
      setParentValue={setImageValue}
      name={name}
      placeholder="Upload File"
      control={control}
      acceptedFileTypes={"image/*"}
    />
  );
}

export function CSVFilePicker({ name, setCSVValue }) {
  const { control } = useForm();

  return (
    <FileUpload
      setParentValue={setCSVValue}
      name={name}
      placeholder="Upload CSV File"
      control={control}
      acceptedFileTypes={".csv"}
    />
  );
}

export function ResumeFilePicker({ name, setResumeValue }) {
  const { control } = useForm();

  return (
    <FileUpload
      setParentValue={setResumeValue}
      name={name}
      placeholder="Upload Resume File"
      control={control}
    />
  );
}
