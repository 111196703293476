import React, { useState } from "react";
import { Button } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";

import colors from "../helpers/colors";

export default function FormModal({
  isOpen,
  onOpen,
  onClose,
  title,
  submitTitle,
  onSubmit,
  children,
}) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody pb="4">
            <Flex direction="column">
              <Text
                fontSize="lg"
                fontWeight="400"
                color={colors.charcoal}
                textAlign={"center"}
                mb="8"
                mt="4"
              >
                {title}
              </Text>
              {children}
              <Button onClick={onSubmit} mb="4" mt="4">
                {submitTitle}
              </Button>
              <Button color="black" variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
