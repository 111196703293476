import React from "react";
import { Flex, Button, useToast, useDisclosure } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";

import {
  AGENCY_CANDIDATE_TRIAGE_ADD_TO_SHORTLIST_MUTATION,
  AGENCY_CANDIDATE_TRIAGE_REMOVE_FROM_SHORTLIST_MUTATION,
  AGENCY_CANDIDATE_TRIAGE_ADD_TO_PASS_LIST_MUTATION,
  AGENCY_CANDIDATE_TRIAGE_MARK_AS_HIRED_MUTATION,
} from "../../graphql/jobs";
import FormModal from "../FormModal";

const onAddToShortlist = ({ toast, moblyzeJob, candidate, refetch }) => {
  MoblyzeApolloClient.mutate({
    mutation: AGENCY_CANDIDATE_TRIAGE_ADD_TO_SHORTLIST_MUTATION,
    variables: {
      moblyzeJobId: moblyzeJob.id,
      candidateUserId: candidate.id,
    },
  })
    .then((result) => {
      if (refetch) {
        refetch();
      }
      toast({
        title: "Candidate added to shortlist",
        status: "success",
      });
    })
    .catch((e) => {
      Sentry.captureException(e);

      alert(e.message);
    });
};

export function AgencyPassAddActions({ candidate, moblyzeJob, refetch }) {
  const toast = useToast();

  const onPass = () => {
    MoblyzeApolloClient.mutate({
      mutation: AGENCY_CANDIDATE_TRIAGE_ADD_TO_PASS_LIST_MUTATION,
      variables: {
        moblyzeJobId: moblyzeJob.id,
        candidateUserId: candidate.id,
      },
    })
      .then((result) => {
        if (refetch) {
          refetch();
        }
        toast({
          title: "Passed on candidate",
          status: "success",
        });
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };

  return (
    <Flex gap="24px">
      <Button variant="outline" colorScheme="red" size="sm" onClick={onPass}>
        Pass
      </Button>
      <Button
        variant="outline"
        colorScheme="green"
        size="sm"
        onClick={() =>
          onAddToShortlist({ toast, moblyzeJob, candidate, refetch })
        }
      >
        Add to Shortlist
      </Button>
    </Flex>
  );
}

export function AgencyRemoveShortlistAction({
  candidate,
  moblyzeJob,
  refetch,
}) {
  const toast = useToast();

  const onRemoveFromShortlist = () => {
    MoblyzeApolloClient.mutate({
      mutation: AGENCY_CANDIDATE_TRIAGE_REMOVE_FROM_SHORTLIST_MUTATION,
      variables: {
        moblyzeJobId: moblyzeJob.id,
        candidateUserId: candidate.id,
      },
    })
      .then((result) => {
        if (refetch) {
          refetch();
        }
        toast({
          title: "Candidate removed from shortlist",
          status: "success",
        });
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };
  return (
    <Flex gap="24px">
      <Button
        variant="outline"
        colorScheme="red"
        size="sm"
        onClick={onRemoveFromShortlist}
      >
        Remove from Shortlist
      </Button>
    </Flex>
  );
}

export function AgencyMarkHiredAction({ candidate, moblyzeJob, refetch }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onMarkAsHired = () => {
    MoblyzeApolloClient.mutate({
      mutation: AGENCY_CANDIDATE_TRIAGE_MARK_AS_HIRED_MUTATION,
      variables: {
        moblyzeJobId: moblyzeJob.id,
        candidateUserId: candidate.id,
      },
    })
      .then((result) => {
        if (refetch) {
          refetch();
        }
        toast({
          title: "Candidate marked as hired",
          status: "success",
        });
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert(e.message);
      });
  };

  return (
    <Flex gap="24px">
      <Button variant="outline" colorScheme="green" size="sm" onClick={onOpen}>
        Mark as Hired
      </Button>

      <FormModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title="Are you sure you want to mark this candidate as hired?"
        onSubmit={onMarkAsHired}
        submitTitle="Yes"
      />
    </Flex>
  );
}

export function AgencyAddToShortlistAction({ candidate, moblyzeJob, refetch }) {
  const toast = useToast();

  return (
    <Flex gap="24px">
      <Button
        variant="outline"
        colorScheme="green"
        size="sm"
        onClick={() =>
          onAddToShortlist({ toast, moblyzeJob, candidate, refetch })
        }
      >
        Add to Shortlist
      </Button>
    </Flex>
  );
}
