import React, { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Text,
} from "@chakra-ui/react";

import { ReactComponent as RightArrow } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as Warning } from "../../assets/icons/warning.svg";

import { Icon } from "@chakra-ui/icons";

export default function ProgressionTabs({
  tabs,
  selectedTabItemName,
  setSelectedTabItemName,
  refetch,
}) {
  const onTabSelected = (tabItemName) => {
    setSelectedTabItemName(tabItemName);
    if (refetch) {
      refetch();
    }
  };

  const renderTabItem = (tabItem, index) => {
    const isSelected = selectedTabItemName === tabItem.name;

    return (
      <BreadcrumbItem key={tabItem.name}>
        <BreadcrumbLink
          color={isSelected ? "white" : "whiteAlpha.500"}
          fontSize="xl"
          fontWeight={isSelected ? "semibold" : "normal"}
          lineHeight="7"
          onClick={() => onTabSelected(tabItem.name)}
        >
          <Flex alignItems="center" gap="8px">
            {tabItem.displayText} <Icon fontSize="14px" as={Warning} />
          </Flex>
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  };

  return (
    <Breadcrumb
      padding="34px 0"
      separator={<Icon fontSize="10px" as={RightArrow} />}
    >
      {tabs.map(renderTabItem)}
    </Breadcrumb>
  );
}
