import React from "react";
import {
  Flex,
  Stack,
  Button,
  Spinner,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useIntercom } from 'react-use-intercom';

import WithAppLayout from "../../components/WithAppLayout";
import { UPDATE_CURRENT_USER_MUTATION } from "../../graphql/users";
import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";
import { Logout } from "../../services/MoblyzeAuth";
import PageTitle from "../../components/PageTitle";

function AccountPage({ data, error, loading, currentUser }) {
  const toast = useToast();
  const { shutdown } = useIntercom();

  const [firstName, setFirstName] = React.useState(currentUser?.firstName);
  const [lastName, setLastName] = React.useState(currentUser?.lastName);
  const [email, setEmail] = React.useState(currentUser?.email);

  React.useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName);
      setLastName(currentUser.lastName);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const onSaveButtonPress = async () => {
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPDATE_CURRENT_USER_MUTATION,
        variables: { firstName, lastName },
      });
      console.log("Update user response", r);

      toast({
        title: "Your profile has been updated",
        status: "success",
      });
    } catch (e) {
      console.log("Update user error", e);
      alert("Error", "There was an error updating your profile.");
    }
  };

  const onLogoutPress = () => {
    // Logout from Intercom library.
    shutdown();
    Logout();
  }

  return (
    <Flex
      direction="column"
      mr={{
        base: "0px",
        md: "40%",
        lg: "50%",
      }}
    >
      {loading && <Spinner size="xl" color="primary.500" />}
      <PageTitle title="Account Settings" />
      <Stack spacing={4} mt="8">
        <Input
          mb="0px"
          placeholder="First name"
          label="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          mb="0px"
          placeholder="Last name"
          label="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          mb="0px"
          placeholder="Email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled
        />

        <Flex align="center">
          <Button width="100%" onClick={onSaveButtonPress}>
            Save
          </Button>
        </Flex>
      </Stack>

      <Button mt="20" onClick={onLogoutPress} colorScheme="red">
        Sign Out
      </Button>
    </Flex>
  );
}

export default WithAppLayout(AccountPage);
