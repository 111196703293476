import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { IsAuthd } from "../services/MoblyzeAuth";

export default function PrivateRoute(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  // Check auth state on component mount
  useEffect(() => {
    const fetchData = async () => {
      const isauthd = await IsAuthd();

      setIsLoggedIn(isauthd);
    };
    fetchData();
  });

  if (isLoggedIn == null) {
    return null;
  } else if (isLoggedIn) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  }
}
