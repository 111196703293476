import React from "react";
import { Flex, Text } from "@chakra-ui/react";

function ShortlistEmptyState({ message }) {
  return (
    <Flex
      p="24px"
      alignItems="center"
      justifyContent="center"
      height="100%"
      direction="column"
    >
      <Text
        textAlign="center"
        maxW="522px"
        fontSize="xl"
        lineHeight="7"
        fontWeight="normal"
        whiteSpace="pre-line"
        color="whiteAlpha.700"
      >
        {message}
      </Text>
    </Flex>
  );
}

export default ShortlistEmptyState;
