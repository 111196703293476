import React from "react";
import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  Input,
  useToast,
  Checkbox,
  FormLabel,
} from "@chakra-ui/react";

import { UPDATE_CANDIDATE_PROFILE_MUTATION } from "../graphql/agencies";
import MoblyzeApolloClient from "../services/MoblyzeApolloClient";

export default function UpdateCandidateProfileForm({ candidateUser, refetch }) {
  const toast = useToast();

  const [firstName, setFirstName] = React.useState(candidateUser?.firstName);
  const [lastName, setLastName] = React.useState(candidateUser?.lastName);
  const [email, setEmail] = React.useState(candidateUser?.email);
  const [primaryPhone, setPrimaryPhone] = React.useState(
    candidateUser?.primaryPhone
  );
  const [confirmChanges, setConfirmChanges] = React.useState(false);

  React.useEffect(() => {
    if (candidateUser) {
      setFirstName(candidateUser.firstName);
      setLastName(candidateUser.lastName);
      setEmail(candidateUser.email);
      setPrimaryPhone(candidateUser.primaryPhone);
    }
  }, [candidateUser]);

  const onSaveButtonPress = async () => {
    if (!confirmChanges) {
      return;
    }
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPDATE_CANDIDATE_PROFILE_MUTATION,
        variables: {
          id: candidateUser.id,
          firstName,
          lastName,
          email,
          primaryPhone,
        },
      });
      console.log("Update user response", r);

      if (refetch) {
        refetch();
      }

      setConfirmChanges(false);

      toast({
        title: "The profile has been updated",
        status: "success",
      });
    } catch (e) {
      console.log("Update user error", e);
      toast({
        title: e?.message || JSON.stringify(e),
        status: "error",
      });
    }
  };

  return (
    <Box borderRadius="5px" background="gray.800" p="44px 20px" width="100%">
      <Text
        color={"white"}
        fontSize={"28px"}
        fontWeight={600}
        lineHeight={"40px"}
      >
        Edit Candidate Profile
      </Text>
      <Stack spacing={4} mt="8" width="100%">
        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          First Name
        </FormLabel>
        <Input
          mt="0px"
          placeholder="First name"
          label="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          Last Name
        </FormLabel>
        <Input
          mt="0px"
          placeholder="Last name"
          label="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          Email
        </FormLabel>
        <Input
          mt="0px"
          placeholder="Email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormLabel fontSize="xs" mb={0} color={"colors.white"}>
          Phone (e.g. +14152231900)
        </FormLabel>
        <Input
          mt="0px"
          placeholder="Phone"
          label="Phone"
          value={primaryPhone}
          onChange={(e) => setPrimaryPhone(e.target.value)}
        />

        {/* checkbox to confirm accuracy of changes */}
        <Checkbox
          colorScheme="green"
          defaultIsChecked={false}
          mb="10px"
          mt="10px"
          isChecked={confirmChanges}
          onChange={(e) => setConfirmChanges(e.target.checked)}
          size="lg"
        >
          <Text color="white">I confirm these changes are accuarate</Text>
        </Checkbox>

        <Flex align="center">
          <Button
            width="100%"
            onClick={onSaveButtonPress}
            disabled={!confirmChanges}
          >
            Update Candidate Profile
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
