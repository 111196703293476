import React from "react";
import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import JobHeader from "../../../components/JobHeader";
import ClientShortlist from "../../../components/Shortlist/ClientShortlist";

import WithAppLayout from "../../../components/WithAppLayout";
import WithQuery from "../../../components/WithQuery";
import { CLIENT_JOB_DETAILS_QUERY } from "../../../graphql/jobs";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

function ClientShortlistPage({ data, error, loading, refetch, currentUser }) {
  const { moblyzeJob, clientTriageListForJob } = data || {};

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Flex h="100%" direction="column">
      <JobHeader moblyzeJob={moblyzeJob} isClient />
      <ClientShortlist
        moblyzeJob={moblyzeJob}
        clientTriageListForJob={clientTriageListForJob}
        refetch={refetch}
      />
    </Flex>
  );
}

function PageComponent({ children }) {
  const { id } = useParams();

  return WithQuery(
    ClientShortlistPage,
    CLIENT_JOB_DETAILS_QUERY,
    { id },
    {
      children,
    }
  )();
}

export default WithAppLayout(PageComponent, {
  showGradientBackground: true,
});
