import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useHistoryState(key, initialValue) {
  const navigate = useNavigate();
  const location = useLocation();

  const [rawState, rawSetState] = useState(() => {
    const value = location?.state?.[key];
    return value ?? initialValue;
  });

  function setState(value) {
    rawSetState(value);
    // replace the current state with the new state
    const currentPath = window.location.pathname;
    navigate(currentPath, {
      state: { ...location?.state, [key]: value },
      replace: true,
    });
  }
  return [rawState, setState];
}
