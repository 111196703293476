import React from "react";
import AppLayout from "../layouts/AppLayout";

const WithAppLayout = (WrappedComponent, otherProps = {}) => {
  const { showGradientBackground } = otherProps;

  return function inner(props) {
    return (
      <AppLayout showGradientBackground={showGradientBackground}>
        <WrappedComponent />
      </AppLayout>
    );
  };
};

export default WithAppLayout;
