import React from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import * as Sentry from "@sentry/react";

// Layout components
import WithQuery from "../components/WithQuery";
import { CURRENT_USER_QUERY } from "../graphql/users";
import { Logout } from "../services/MoblyzeAuth";
import MoblyzeLocalStorage, {
  LOCAL_STORAGE_KEYS,
} from "../services/MoblyzeLocalStorage";
import { HasAnalytics } from "../services/MoblyzeAuth";

import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {
  INTERCOM_APP_ID_JAB,
  INTERCOM_APP_ID_MOBLYZE,
} from "../helpers/config";

const CandididateLayout = () => {
  const { shutdown } = useIntercom();
  const onLogoutPress = () => {
    // Logout from Intercom library.
    shutdown();
    Logout();
  };

  return (
    <Flex as="section" minH="100vh" flexDir="column">
      <Flex as="section">
        <Box flex={1} width={"100%"}>
          <Box
            p="6"
            bg="red.500"
            color="white"
            fontWeight="bold"
            borderRadius="md"
          >
            You are not authorized to access this page.
          </Box>
        </Box>
      </Flex>

      <Button mt={10} onClick={onLogoutPress} colorScheme="red">
        Sign Out
      </Button>
    </Flex>
  );
};

const BaseLayout = ({
  children,
  showSidebar,
  showGradientBackground,
  currentUser,
}) => {
  return (
    <Flex as="section" minH="100vh" flexDir="column">
      <Navbar currentUser={currentUser} />

      <Flex as="section" flex="1" overflowX="scroll" flexDir="row">
        {showSidebar && <Sidebar currentUser={currentUser} />}
        <Box
          flex={1}
          py="10"
          px="8"
          position="relative"
          bg={
            showGradientBackground
              ? "linear-gradient(180deg, rgba(41, 118, 188, 0.20) 6.69%, rgba(0, 0, 0, 0.00) 45.12%), #1D1D1D"
              : "var(--gray-1000, #1D1D1D);"
          }
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

function AppLayoutInner(props) {
  const { boot } = useIntercom();
  const {
    children,
    data,
    loading,
    refetch,
    showGradientBackground,
    initializeIntercom,
  } = props;

  const [userRole, setUserRole] = React.useState(data?.currentUser?.role);

  React.useEffect(() => {
    if (data?.currentUser?.role && initializeIntercom) {
      setUserRole(data?.currentUser?.role);
      // Boot the Intercom library with the user's data.
      const name = `${data?.currentUser?.firstName} ${data?.currentUser?.lastName}`;
      const userId = data?.currentUser?.id;
      const email = data?.currentUser?.email;
      const role = data?.currentUser?.role;

      boot({
        userId,
        name: name,
        email: email,
        customAttributes: { role },
      });

      const setAnalyticsIdentify = async () => {
        const hasAnalyticsValue = await HasAnalytics();
        if (!hasAnalyticsValue) {
          // notify segment of logged in user
          window.analytics.identify(userId, {
            name: name,
            email: email,
            role: role,
          });
          await MoblyzeLocalStorage.save({
            key: LOCAL_STORAGE_KEYS.ANALYTICS,
            data: true,
          });
        }
      };
      setAnalyticsIdentify();

      // identify on sentry
      Sentry.setUser({
        id: userId,
        email: email,
      });

      window.analytics.page();
    }
  }, [data?.currentUser, data?.currentUser?.role, boot, initializeIntercom]);

  const currentUser = data?.currentUser;

  // Display error message if a candidate happens to access this app
  if (userRole === "Candidate") {
    return <CandididateLayout />;
  }

  return (
    <BaseLayout
      showSidebar={userRole === "Recruiter"}
      showGradientBackground={showGradientBackground}
      currentUser={currentUser}
    >
      {/* embed current user prop on children, along with loading and refetch */}
      {React.cloneElement(children, { loading, currentUser, refetch })}
    </BaseLayout>
  );
}

function AppLayoutIntercomWrapper(props) {
  const { data } = props;

  const isJabAgency =
    data?.currentUser?.clientOrganization?.agencySlugId === "JAB" ||
    data?.currentUser?.recruiterAgencySlugId === "JAB";

  const initializeIntercom =
    data?.currentUser?.clientOrganization?.agencySlugId ||
    data?.currentUser?.recruiterAgencySlugId;

  return (
    <IntercomProvider
      appId={isJabAgency ? INTERCOM_APP_ID_JAB : INTERCOM_APP_ID_MOBLYZE}
      shouldInitialize={initializeIntercom}
    >
      <AppLayoutInner {...props} initializeIntercom={initializeIntercom} />
    </IntercomProvider>
  );
}

export default function AppLayout({ showGradientBackground, children }) {
  return WithQuery(AppLayoutIntercomWrapper, CURRENT_USER_QUERY, null, {
    children,
    showGradientBackground,
  })();
}
