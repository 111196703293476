import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import moment from "moment";

import { GetLocalAuthRole } from "../services/MoblyzeAuth";
import { ReactComponent as Pin } from "../assets/icons/pin.svg";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import { ReactComponent as People } from "../assets/icons/people.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { FaDollarSign } from "react-icons/fa";

import { Icon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";

import { AgencyEditArchiveJobMenu } from "./AgencyJobActions";
import JobDetails from "./JobDetails";
import { formatPayFrequency } from "../helpers/strings";

export default function JobHeader({
  moblyzeJob,
  isClient,
  isAgency,
  isArchived,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showJobDetail, setShowJobDetail] = useState();

  const [role, setRole] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const authRole = await GetLocalAuthRole();
      setRole(authRole);
    };
    fetchData();
  }, []);

  if (!moblyzeJob) {
    return null;
  }

  const renderActions = () => {
    if (isClient) {
      return (
        <Flex alignItems="center" direction="row" gap="16px" h="40px">
          <Button
            color="whiteAlpha.700"
            _hover={{ bg: "whiteAlpha.50" }}
            variant="ghost"
          >
            Recruiter Details
          </Button>
          <Button
            color="whiteAlpha.700"
            _hover={{ bg: "whiteAlpha.50" }}
            variant="outline"
            onClick={() => setShowJobDetail(true)}
          >
            View Job Details
          </Button>
        </Flex>
      );
    } else if (isAgency) {
      return (
        <>
          <Flex alignItems="center" direction="row" gap="16px" h="40px">
            <Button
              color="whiteAlpha.700"
              _hover={{ bg: "whiteAlpha.50" }}
              variant="ghost"
              onClick={() => setShowJobDetail(true)}
            >
              View Job Details
            </Button>
          </Flex>
          {!isArchived && <AgencyEditArchiveJobMenu moblyzeJob={moblyzeJob} />}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Flex paddingBottom="34px" align="center">
        <Text
          onClick={() => {
            // if we can naviate to the previous page, do so
            // otherwise, navigate to the home page
            if (window.history.length > 1) {
              navigate(-1);
            } else {
              navigate("/");
            }
          }}
          fontSize="md"
          lineHeight="6"
          fontWeight="normal"
          cursor="pointer"
        >
          <Icon fontSize="10px" as={LeftArrow} /> Back
        </Text>
      </Flex>
      <Flex borderBottom="1px solid #6F6F6F" gap="20px" w="100%" p="26px 0">
        <Image
          h={148}
          w={250}
          borderRadius={6}
          src={moblyzeJob?.coverImageUrl}
        />
        <Flex
          flex={1}
          gap="20px"
          direction="column"
          justifyContent="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="semibold" lineHeight="7">
              {moblyzeJob?.category}
            </Text>
            <Text fontSize="md" fontWeight="normal" lineHeight="6">
              {moblyzeJob?.title}
            </Text>
            {role === "Recruiter" && moblyzeJob?.bullhornId && (
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                Bullhorn Id: {moblyzeJob?.bullhornId}
              </Text>
            )}
          </Box>

          <Flex gap="16px" direction="column">
            <Flex wrap="wrap" gap="16px">
              <Icon fontSize={20} as={Pin} />
              <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                {moblyzeJob?.location}
              </Text>

              <Flex gap="16px">
                <Icon fontSize={20} as={Calendar} />
                <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                  Starting{" "}
                  {moment(moblyzeJob?.startDate).format("MMMM Do, YYYY")}
                </Text>
              </Flex>
            </Flex>
            <Flex wrap="wrap" gap="16px">
              <Flex gap="16px">
                <Icon fontSize={20} as={People} />
                <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                  {moblyzeJob?.numOpenings} Opening
                  {moblyzeJob?.numOpenings !== 1 && "s"}
                </Text>
              </Flex>
              {moblyzeJob?.payRateValue && moblyzeJob?.payRateValue !== "" && (
                <Flex gap="16px">
                  <Icon fontSize={20} as={FaDollarSign} />
                  <Text fontSize="sm" fontWeight="normal" lineHeight="5">
                    {`${moblyzeJob?.payRateValue} ${
                      moblyzeJob?.payCurrency
                    }/${formatPayFrequency(moblyzeJob?.payFrequency)}`}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        {renderActions()}
        <JobDetails
          isOpen={showJobDetail}
          onClose={() => setShowJobDetail(false)}
          moblyzeJob={moblyzeJob}
          isClient={role === "Client"}
        />
      </Flex>
    </>
  );
}
