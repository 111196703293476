import { gql } from "@apollo/client";

import { MOBLYZE_JOB_FRAGMENT } from "./jobs";

export const CLIENT_ORGANIZATION_FRAGMENT = gql`
  fragment ClientOrganizationFragment on ClientOrganization {
    id
    name
    logoUrl
    websiteUrl

    clientOrganizationReport {
      numActiveJobs
      locationsDescription
    }
  }
`;

export const ALL_CLIENT_ORGS_QUERY = gql`
  ${CLIENT_ORGANIZATION_FRAGMENT}
  {
    getClientOrganizations {
      ...ClientOrganizationFragment
    }
  }
`;

export const CLIENT_DETAILS_PAGE_QUERY = gql`
  ${CLIENT_ORGANIZATION_FRAGMENT}
  ${MOBLYZE_JOB_FRAGMENT}
  query ClientDetailsQuery($id: ID!) {
    getClientOrganization(id: $id) {
      ...ClientOrganizationFragment
    }

    getJobsForClientOrganization(id: $id) {
      ...MoblyzeJobFragment
    }
  }
`;

export const CLIENT_ORG_QUERY = gql`
  ${CLIENT_ORGANIZATION_FRAGMENT}
  query ClientOrgQuery($id: ID!) {
    getClientOrganization(id: $id) {
      ...ClientOrganizationFragment
    }
  }
`;

export const CREATE_CLIENT_ORG_MUTATION = gql`
  ${CLIENT_ORGANIZATION_FRAGMENT}
  mutation CreateClientOrgMutation($name: String!, $websiteUrl: String!) {
    createClientOrganization(name: $name, websiteUrl: $websiteUrl) {
      ...ClientOrganizationFragment
    }
  }
`;

export const UPDATE_CLIENT_ORG_MUTATION = gql`
  ${CLIENT_ORGANIZATION_FRAGMENT}
  mutation UpdateClientOrgMutation(
    $clientOrganizationId: ID!
    $name: String
    $websiteUrl: String
  ) {
    updateClientOrganization(
      clientOrganizationId: $clientOrganizationId
      name: $name
      websiteUrl: $websiteUrl
    ) {
      ...ClientOrganizationFragment
    }
  }
`;

export const UPLOAD_CLIENT_ORG_LOGO_MUTATION = gql`
  mutation UploadClientOrgLogoMutation(
    $clientOrganizationId: ID!
    $imageFile: Upload!
  ) {
    uploadClientOrganizationLogo(
      clientOrganizationId: $clientOrganizationId
      imageFile: $imageFile
    )
  }
`;

export const INVITE_CLIENT_CONTACT_URL_MUTATION = gql`
  mutation InviteClientContactUrlMutation(
    $clientOrganizationId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    inviteClientContactUrl(
      clientOrganizationId: $clientOrganizationId
      firstName: $firstName
      lastName: $lastName
      email: $email
    )
  }
`;
