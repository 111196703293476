import React from "react";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { IntercomProvider } from "react-use-intercom";

import MoblyzeApolloClient from "./services/MoblyzeApolloClient";
import {
  GetSentryEnvironment,
  GetSentryReplaySessionSampleRate,
  GetSentryDebug,
  GetSentryEnabled,
  APP_VERSION,
  SENTRY_DSN_URL,
  INTERCOM_APP_ID,
} from "./helpers/config";

import "./assets/css/App.css";
import moblyzeTheme from "./theme";

import PrivateRoute from "./pages/PrivateRoute";
import AgencyRoute from "./pages/AgencyRoute";
import ClientRoute from "./pages/ClientRoute";

import LoginPage from "./pages/public/Login";
import ResetPasswordPage from "./pages/public/ResetPassword";
import ResetCompletePassword from "./pages/public/ResetCompletePassword";
import ClaimInvitePage from "./pages/public/ClaimInvite";

import DashboardPage from "./pages/private/Dashboard";
import AccountPage from "./pages/private/AccountPage";

import UncuratedJobsPage from "./pages/private/agency/UncuratedJobs";
import ActiveJobsPage from "./pages/private/agency/ActiveJobs";
import ArchivedJobsPage from "./pages/private/agency/ArchivedJobs";
import CreateEditJobPage from "./pages/private/agency/CreateEditJob";
import CurateJobPage from "./pages/private/agency/CurateJobPage";
import JobDetailsPage from "./pages/private/agency/JobDetails";
import ImportJobsPage from "./pages/private/agency/ImportJobs";

import AllClientsPage from "./pages/private/agency/AllClients";
import ClientDetailPage from "./pages/private/agency/ClientDetails";
import ClientEditCreatePage from "./pages/private/agency/ClientEditCreatePage";

import AllRecruitersPage from "./pages/private/agency/AllRecruiters";
import AllCandidatesPage from "./pages/private/agency/AllCandidates";
import CandidateDetailsPage from "./pages/private/agency/CandidateDetails";
import CandidateInvitePage from "./pages/private/agency/CandidateInvite";

import ClientDashboardPage from "./pages/private/client/Dashboard";
import ClientShortlistPage from "./pages/private/client/Shortlist";

import {
  // Public routes
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  RESET_COMPLETE_PASSWORD_PATH,
  CLAIM_INVITE_PATH,

  // Universal private routes
  DASHBOARD_PATH,
  ACCOUNT_PATH,

  // Recruiter routes
  UNCURATED_JOBS_PATH,
  ACTIVE_JOBS_PATH,
  ARCHIVED_JOBS_PATH,
  ADD_JOB_PATH,
  EDIT_JOB_PATH,
  CURATE_JOB_PATH,
  ALL_CLIENTS_PATH,
  ADD_CLIENT_PATH,
  EDIT_CLIENT_PATH,
  ALL_RECRUITERS_PATH,
  JOB_DETAILS_PATH,
  CLIENT_DETAILS_PATH,
  IMPORT_JOBS_PATH,
  ALL_CANDIDATES_PATH,
  EDIT_CANDIDATE_PATH,

  // Client routes
  CLIENT_DASHBOARD_PATH,
  CLIENT_SHORTLIST_PATH,
  INVITE_CANDIDATE_PATH,
} from "./routes";

import { Replay } from "@sentry/browser";

Sentry.init({
  dsn: SENTRY_DSN_URL,
  release: APP_VERSION,
  environment: GetSentryEnvironment(),
  autoSessionTracking: true,
  enabled: GetSentryEnabled(),
  debug: GetSentryDebug(),
  replaysSessionSampleRate: GetSentryReplaySessionSampleRate(),
  replaysOnErrorSampleRate: 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  integrations: [new BrowserTracing()],
});

Sentry.addIntegration(new Replay({ maskAllText: false }));

function App() {
  return (
    <ApolloProvider client={MoblyzeApolloClient}>
      <BrowserRouter>
        <ChakraProvider
          theme={moblyzeTheme}
          toastOptions={{ defaultOptions: { position: "top" } }}
        >
          <Routes>
            {/* Public routes */}
            <Route path={LOGIN_PATH} element={<LoginPage />} />
            <Route path={RESET_PASSWORD_PATH} element={<ResetPasswordPage />} />
            <Route
              path={RESET_COMPLETE_PASSWORD_PATH}
              element={<ResetCompletePassword />}
            />
            <Route path={CLAIM_INVITE_PATH} element={<ClaimInvitePage />} />

            {/* Private routes */}
            <Route path="/" element={<PrivateRoute />}>
              <Route
                exact
                path="/"
                element={<Navigate to={DASHBOARD_PATH} />}
              />
              <Route path={DASHBOARD_PATH} element={<DashboardPage />} />
              <Route path={ACCOUNT_PATH} element={<AccountPage />} />

              <Route path="/agency" element={<AgencyRoute />}>
                <Route
                  exact
                  path="/agency"
                  element={<Navigate to={UNCURATED_JOBS_PATH} />}
                />
                <Route
                  path={UNCURATED_JOBS_PATH}
                  element={<UncuratedJobsPage />}
                />
                <Route path={ACTIVE_JOBS_PATH} element={<ActiveJobsPage />} />
                <Route
                  path={ARCHIVED_JOBS_PATH}
                  element={<ArchivedJobsPage />}
                />
                <Route path={ADD_JOB_PATH} element={<CreateEditJobPage />} />
                <Route path={EDIT_JOB_PATH} element={<CreateEditJobPage />} />
                <Route path={CURATE_JOB_PATH} element={<CurateJobPage />} />
                <Route path={IMPORT_JOBS_PATH} element={<ImportJobsPage />} />

                <Route path={ALL_CLIENTS_PATH} element={<AllClientsPage />} />
                <Route
                  path={ADD_CLIENT_PATH}
                  element={<ClientEditCreatePage />}
                />
                <Route
                  path={EDIT_CLIENT_PATH}
                  element={<ClientEditCreatePage />}
                />

                <Route
                  path={ALL_RECRUITERS_PATH}
                  element={<AllRecruitersPage />}
                />
                <Route path={JOB_DETAILS_PATH} element={<JobDetailsPage />} />

                <Route
                  path={CLIENT_DETAILS_PATH}
                  element={<ClientDetailPage />}
                />

                <Route
                  path={ALL_CANDIDATES_PATH}
                  element={<AllCandidatesPage />}
                />

                <Route
                  path={EDIT_CANDIDATE_PATH}
                  element={<CandidateDetailsPage />}
                />

                <Route
                  path={INVITE_CANDIDATE_PATH}
                  element={<CandidateInvitePage />}
                />
              </Route>

              <Route path="/client" element={<ClientRoute />}>
                <Route
                  exact
                  path="/client"
                  element={<Navigate to={CLIENT_DASHBOARD_PATH} />}
                />
                <Route
                  exact
                  path={CLIENT_DASHBOARD_PATH}
                  element={<ClientDashboardPage />}
                />
                <Route
                  path={CLIENT_SHORTLIST_PATH}
                  element={<ClientShortlistPage />}
                />
              </Route>
            </Route>
          </Routes>
        </ChakraProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
