import React, { useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";

import { ReactComponent as Information } from "../../assets/icons/information.svg";
import ShortlistEmptyState from "./ShortlistEmptyState";
import CandidateListItem from "./CandidateListItem";
import CandidateDetails from "./CandidateDetails";
import ProgressionTabs from "./ProgressionTabs";

import {
  CandidateTriageStatusApprovedByRecruiter,
  CandidateTriageStatusApprovedByClient,
  CandidateTriageStatusPassedByClient,
  CandidateTriageStatusHired,
} from "../../graphql/jobs";

const shortlistTab = "Shortlist";
const hiredTab = "Hired";
const tabItems = [
  { name: shortlistTab, displayText: shortlistTab },
  { name: hiredTab, displayText: hiredTab },
];

function ClientShortlistBody({
  tabName,
  clientTriageListForJob,
  moblyzeJob,
  refetch,
}) {
  let filteredTriageList = React.useMemo(() => [], []);

  let emptyMessage = "";

  if (tabName === shortlistTab) {
    filteredTriageList = clientTriageListForJob.filter(
      (candidateTriage) => candidateTriage.status !== CandidateTriageStatusHired
    );

    emptyMessage = `You have no candidates to review.

Keep an eye out for the recruiter to start building the shortlist for this job.

We'll notify you when you have candidates to review.`;
  } else if (tabName === hiredTab) {
    filteredTriageList = clientTriageListForJob.filter(
      (candidateTriage) => candidateTriage.status === CandidateTriageStatusHired
    );

    emptyMessage = `No candidates have been hired yet.

Once a candidate is hired they will appear here.`;
  }

  const [candidateTriage, setCandidateTriage] = useState(
    filteredTriageList?.length > 0 ? filteredTriageList[0] : null
  );

  // if filteredTriageList changes, select the same candidateTriage or the first one
  React.useEffect(() => {
    if (candidateTriage) {
      const newCandidateTriage = filteredTriageList.find(
        (ct) => ct.id === candidateTriage.id
      );
      if (newCandidateTriage) {
        setCandidateTriage(newCandidateTriage);
      } else {
        setCandidateTriage(filteredTriageList[0]);
      }
    }
  }, [filteredTriageList, candidateTriage]);

  if (filteredTriageList.length === 0) {
    return <ShortlistEmptyState message={emptyMessage} />;
  }

  return (
    <Flex gap="50px">
      <CandidateListItem
        setSelect={setCandidateTriage}
        select={candidateTriage}
        candidateTriageList={filteredTriageList}
        isClient
      />
      {candidateTriage && (
        <CandidateDetails
          candidateTriage={candidateTriage}
          isClient
          showClientPassAddActions={tabName === shortlistTab}
          moblyzeJob={moblyzeJob}
          refetch={refetch}
        />
      )}
    </Flex>
  );
}

export default function ClientShortlist({
  moblyzeJob,
  clientTriageListForJob,
  refetch,
}) {
  const [selectedTabItemName, setSelectedTabItemName] = useState(shortlistTab);

  if (!moblyzeJob) {
    return null;
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <ProgressionTabs
          tabs={tabItems}
          selectedTabItemName={selectedTabItemName}
          setSelectedTabItemName={setSelectedTabItemName}
          refetch={refetch}
        />
        {clientTriageListForJob.length > 0 &&
          selectedTabItemName === shortlistTab && (
            <Flex alignItems="center" gap="12px">
              <Icon fontSize="24px" as={Information} />
              <Text fontSize="md" fontWeight="normal" lineHeight="6">
                Let us know if you think this candidate is a good fit and we'll
                get you connected!
              </Text>
            </Flex>
          )}
      </Flex>

      <ClientShortlistBody
        tabName={selectedTabItemName}
        clientTriageListForJob={clientTriageListForJob}
        moblyzeJob={moblyzeJob}
        refetch={refetch}
      />
    </>
  );
}
