import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import colors from "../helpers/colors";

export const NavButton = (props) => {
  const { icon, label, path, count, showDot, onClick, ...buttonProps } = props;

  let linkPath = path;
  if (!path || path === "") {
    linkPath = "/";
  }

  let active = false;
  if (window.location.pathname === linkPath) {
    active = true;
  }

  const renderButton = (passedProps) => {
    return (
      <Button
        variant="tertiary"
        justifyContent="start"
        {...passedProps}
        w={"full"}
        pl="6"
        pr="4"
        my={0}
        py="4"
        borderRadius={0}
        role="group"
        backgroundColor={active ? colors.greyBorderDark : colors.transparent}
        _hover={{ backgroundColor: colors.white }}
        borderRight={
          active
            ? `3px solid ${colors.white}`
            : `3px solid ${colors.transparent}`
        }
      >
        <HStack justifyContent={"space-between"} w={"full"}>
          <Text
            color={active ? colors.white : colors.offWhite}
            _groupHover={{ color: colors.greyDark }}
          >
            {label}
          </Text>

          {/* show count if provided */}
          {count && (
            <Text
              color={active ? colors.white : colors.offWhite}
              _groupHover={{ color: colors.greyDark }}
              fontSize={"12px"}
            >
              {count}
            </Text>
          )}

          {/* show dot if provided */}
          {showDot && (
            <div
              style={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                backgroundColor: colors.brandBlue,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}

          {/* change icon style on button hover */}
          {icon && (
            <Icon
              as={icon}
              boxSize="6"
              color={active ? colors.white : colors.offWhite}
              ml="auto"
              _groupHover={{ color: colors.greyDark }}
            />
          )}
        </HStack>
      </Button>
    );
  };

  if (onClick) {
    // render as button
    const newButtonProps = {
      onClick,
      ...buttonProps,
    };
    return renderButton(newButtonProps);
  }

  return <Link to={linkPath}>{renderButton(buttonProps)}</Link>;
};
