import { gql } from "@apollo/client";

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragment on User {
    id
    firstName
    lastName
    email

    role

    clientOrganization {
      id
      name
      logoUrl
      agencySlugId
    }

    recruiterOrganizationReport {
      uncuratedJobsCount
    }

    recruiterAgencySlugId
  }
`;

export const CURRENT_USER_QUERY = gql`
  ${CURRENT_USER_FRAGMENT}
  {
    currentUser {
      ...CurrentUserFragment
    }
  }
`;

export const UPDATE_CURRENT_USER_MUTATION = gql`
  mutation UpdateUserProfile($firstName: String!, $lastName: String!) {
    updateUserProfile(input: { firstName: $firstName, lastName: $lastName }) {
      id
      firstName
      lastName
    }
  }
`;
