const colors = {
  // Standard colors
  none: null,
  black: "#000",
  white: "#fff",
  offWhite: "#D2D2D2",
  transparent: "rgba(0, 0, 0, 0.0)",
  modalBackdrop: "rgba(0, 0, 0, 0.7)",

  // Moblyze colors
  brandGreen: "#448087",
  brandBlue: "#2976BC",

  charcoal: "#272727",
  charcoalLight: "#3D3D3D",
  charcoalText: "#898989",
  greyDark: "#1D1D1D",
  greyMedium: "#414141",
  greyMediumLight: "#8E8E8E",
  greyLight: "#B0B0B0",

  greyBorderDark: "#535353",
  greyBorder: "#5D5D5D",
  greyBorderLight: "#939393",

  lightBlue: "#90A9D5",
  lightBlueBg: "rgba( 144, 169, 213, .2)",

  // Old
  greyFeatherLight: "#FBFBFB",
  titleColorShadow: "rgba(0, 0, 0, 0.15)",
  ink: "#222B45",
  electricBlue: "#3366FF",
  red: "#ff0033",

  // Eva colors
  success: "#00D68F",
  info: "#0B77EA",
  warning: "#FF9102",
  danger: "#FF3D71",
};

export default colors;
