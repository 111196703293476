import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import {
  CREATE_VESSEL_MUTATION,
  UPLOAD_VESSEL_IMAGE_MUTATION,
} from "../graphql/vessels";

import MoblyzeApolloClient from "../services/MoblyzeApolloClient";

import {
  BasicInput,
  ImageUpload,
  SelectInput,
  SectionTitle,
} from "./HookFormsInputs";
import { IsProduction } from "../helpers/config";

const isSmokingOptions = [
  { label: "Smoking", value: "Smoking" },
  { label: "Non-Smoking", value: "Non-Smoking" },
];

export default function AddVesselModal({
  loading,
  data,
  error,
  refetch,
  refetchParent,
  setNewVesselId,
}) {
  const isCreate = true;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const {
    handleSubmit: handleSubmitModal,
    register: registerModal,
    control: controlModal,
    formState: { errors: errorsModal, isSubmitting: isSubmittingModal },
  } = useForm({});

  const uploadVesselImage = async ({ imageFile, vesselId }) => {
    if (!imageFile) {
      return;
    }

    await MoblyzeApolloClient.mutate({
      mutation: UPLOAD_VESSEL_IMAGE_MUTATION,
      variables: { imageFile, vesselId },
    });

    if (refetchParent) {
      await refetchParent();
    }

    toast({
      title: "Vessel image uploaded",
      status: "success",
    });

    onClose();
  };

  const createVesselAndUploadImage = async ({ variables, imageFile }) => {
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: CREATE_VESSEL_MUTATION,
        variables,
      });

      console.log(r);

      const vesselId = r?.data?.createVessel?.id;

      await uploadVesselImage({ imageFile, vesselId });

      if (setNewVesselId) {
        setNewVesselId(vesselId);
      }

      toast({
        title: "Vessel created!",
        status: "success",
      });

      onClose();
    } catch (e) {
      console.log("Create job error", e);

      toast({
        title: "Error creating vessel",
        subtitle: e.message,
        status: "error",
      });
    }
  };

  const updateVesselAndUploadLogo = async () => {};

  async function onSubmit(values) {
    const {
      name,
      subtitle,
      flag,
      ageDescription,
      wifiSpeedDescription,
      isSmokingSelectedOption,
      totalCrew,
      vesselUrl,
      vesselImage,
    } = values;

    const variables = {
      name,
      subtitle,
      flag,
      ageDescription,
      wifiSpeedDescription,
      isSmoking: isSmokingSelectedOption?.value,
      totalCrew,
      vesselUrl,
    };

    if (!IsProduction()) {
      console.log(values);
      console.log(variables);
    }

    if (isCreate) {
      await createVesselAndUploadImage({ variables, imageFile: vesselImage });
    } else {
      updateVesselAndUploadLogo({ variables, imageFile: vesselImage });
    }
  }

  if (loading || error) {
    return null;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} variant={"darkMode"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody pb="4">
            <Flex direction="column">
              <form
                onSubmit={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleSubmitModal(onSubmit)(event);
                }}
              >
                {/* Client details */}
                <SectionTitle title="Add Vessel" />

                <BasicInput
                  label="Name"
                  name="name"
                  placeholder="Boaty McBoatface"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                  required
                />

                <BasicInput
                  label="Subtitle"
                  placeholder="IMO ___, MMSI ___"
                  name="subtitle"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                />

                <BasicInput
                  label="Flag"
                  name="flag"
                  placeholder="United Kingdom"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                />

                <BasicInput
                  label="Year Built"
                  name="ageDescription"
                  placeholder="Built 2010"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                />

                <BasicInput
                  label="WiFi Speed"
                  name="wifiSpeedDescription"
                  placeholder="WiFi Onboard - 4G"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                />

                <SelectInput
                  label="Smoking Allowed"
                  name="isSmokingSelectedOption"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                  options={isSmokingOptions}
                />

                <BasicInput
                  label="Total Crew"
                  name="totalCrew"
                  placeholder="Total Crew"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                />

                <BasicInput
                  label="URL"
                  name="vesselUrl"
                  placeholder="https://www.example.com"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                />

                <ImageUpload
                  label="Image"
                  name="vesselImage"
                  register={registerModal}
                  control={controlModal}
                  errors={errorsModal}
                  required
                />

                <Button mt={4} isLoading={isSubmittingModal} type="submit">
                  Save
                </Button>
              </form>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Button onClick={onOpen} variant="outline" ml="3">
        Add New Vessel
      </Button>
    </>
  );
}
